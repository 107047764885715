import { mdiCheckboxMarked } from '@mdi/js'
import Icon from '@mdi/react'
import clsx from 'clsx'

export default ({
  text,
  checked,
  onClick
}: {
  text: string
  checked: boolean
  onClick: () => void
}) => {
  return (
    <div
      className={clsx(
        'text-sm cursor-pointer col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 flex items-center justify-between py-2 px-4 rounded-full',
        checked
          ? 'bg-primary text-white hover:bg-green-500'
          : 'border border-gray-200 text-gray-700 hover:bg-gray-100'
      )}
      onClick={onClick}
    >
      <div>{text}</div>
      <Icon
        path={mdiCheckboxMarked}
        color={checked ? 'white' : 'transparent'}
        size={0.75}
      />
    </div>
  )
}
