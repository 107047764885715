import Campaign from '../types/Campaign'
import campaignState from './campaignState'

const DAY_MILLIS = 8.64e7
const HOUR_MILLIS = 3.6e6
const MINUTE_MILLIS = 60000
const SECONDS_MILLIS = 1000

export default (campaign: Campaign) => {
  let days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0
  const now = Date.now(),
    endsAt = campaign.endsAt.toMillis(),
    startsAt = campaign.startsAt.toMillis()

  const { started, finished } = campaignState(campaign)

  if (!finished) {
    const diff = Math.abs(now - (!started ? startsAt : endsAt))
    days = Math.floor(diff / DAY_MILLIS)
    let buffer = days * DAY_MILLIS
    hours = Math.floor((diff - buffer) / HOUR_MILLIS)
    buffer += hours * HOUR_MILLIS
    minutes = Math.floor((diff - buffer) / MINUTE_MILLIS)
    buffer += minutes * MINUTE_MILLIS
    seconds = Math.floor((diff - buffer) / SECONDS_MILLIS)
  }

  return {
    days,
    hours,
    minutes,
    seconds,
    future: !started
  }
}
