import firebase from 'firebase/app'
import { orderBy } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import BoardState from '../../types/BoardState'
import Campaign from '../../types/Campaign'
import Ticket from '../../types/Ticket'
import campaignState from '../../utils/campaignState'
import { collections } from '../../utils/firebase'
import formatMoney from '../../utils/formatMoney'
import getCountdown from '../../utils/getCountdown'
import DashboardStat from './DashboardStat'

const Countdown = ({ campaign }: { campaign: Campaign }) => {
  const [, setR] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setR(Math.random())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  if (campaign.canceled) {
    return (
      <div className='p-3 bg-red-500 text-white grid grid-cols-12 gap-3 items-center'>
        <div className='text-xs font-bold col-span-6 md:col-span-4'>
          Canceled
        </div>
      </div>
    )
  }

  const { finished, started } = campaignState(campaign)

  if (finished) {
    return (
      <div className='p-3 bg-primary text-white grid grid-cols-12 gap-3 items-center'>
        <div className='text-xs font-bold col-span-6 md:col-span-4'>
          Finished
        </div>
      </div>
    )
  }

  const { days, hours, minutes, seconds } = getCountdown(campaign)

  return (
    <div className='p-3 bg-dark-blue text-white grid grid-cols-12 gap-3 items-center'>
      <div className='text-xs font-bold col-span-6 md:col-span-4'>
        {started ? 'Ending in' : 'Starting in'}
      </div>
      <div className='flex items-center flex-1 justify-between col-span-6 md:col-span-4'>
        <div className='flex flex-col items-center mx-1'>
          <div className='text-xs'>Days</div>
          <div className='text-lg font-bold'>{days}</div>
        </div>
        <div className='flex flex-col items-center mx-1'>
          <div className='text-xs'>Hours</div>
          <div className='text-lg font-bold'>{hours}</div>
        </div>
        <div className='flex flex-col items-center mx-1'>
          <div className='text-xs'>Minutes</div>
          <div className='text-lg font-bold'>{minutes}</div>
        </div>
        <div className='flex flex-col items-center mx-1'>
          <div className='text-xs'>Seconds</div>
          <div className='text-lg font-bold'>{seconds}</div>
        </div>
      </div>
      <button
        className='button button-outline-light col-span-12 md:col-span-4'
        onClick={async () => {
          if (window.confirm('Cancel this campaign?')) {
            collections.campaigns.doc(campaign.id).update({
              finishedAt: firebase.firestore.FieldValue.serverTimestamp(),
              canceled: true
            })
          }
        }}
      >
        Cancel
      </button>
    </div>
  )
}

export default ({
  campaign,
  tickets
}: {
  campaign: Campaign
  tickets: Ticket[]
}) => {
  const board = new BoardState(tickets, campaign)
  const transactions = useMemo(() => {
    const transactions: Transaction[] = []

    tickets.forEach(t => {
      transactions.push({
        id: t.id,
        amount: t.paid,
        date: t.createdAt,
        details: 'purchase'
      })
    })

    return orderBy(transactions, 'date', 'desc')
  }, [tickets])

  return (
    <div>
      <Countdown campaign={campaign} />
      <div className='flex items-center mt-4'>
        <div className='text-lg'>Statistics</div>
      </div>
      <div className='grid grid-cols-12 mt-3 gap-3'>
        <DashboardStat
          title='Gross Sales'
          value={formatMoney(campaign.raised)}
          colClasses='col-span-12 md:col-span-6'
        />
        <DashboardStat
          title='Ticket Buyers'
          value={campaign.participants}
          colClasses='col-span-12 md:col-span-6'
        />
        <DashboardStat
          title='Tickets Sold'
          value={campaign.spots - campaign.spotsAvailable}
          colClasses='col-span-12 md:col-span-4'
        />
        <DashboardStat
          title='Full Tickets'
          value={board.fullyTaken}
          colClasses='col-span-12 md:col-span-4'
        />
        <DashboardStat
          title='Partial'
          value={board.partiallyOpen}
          colClasses='col-span-12 md:col-span-4'
        />
      </div>
      <div className='mt-4 flex-items-center'>
        <div className='text-lg'>Transactions</div>
        <div className='grid grid-cols-12 gap-3 px-4 py-3'>
          <div className='col-span-4 text-xs font-bold text-gray-400'>
            TRANSACTION ID
          </div>
          <div className='col-span-2 text-xs font-bold text-gray-400'>
            AMOUNT
          </div>
          <div className='col-span-3 text-xs font-bold text-gray-400'>
            DETAILS
          </div>
          <div className='col-span-3 text-xs font-bold text-gray-400'>
            DATE
          </div>
        </div>
        {transactions.map(t => {
          return (
            <div
              className='grid grid-cols-12 gap-3 p-4 bg-white border-b border-gray-100'
              key={t.id}
            >
              <div className='col-span-4 text-xs'>{t.id}</div>
              <div className='col-span-2 text-xs'>
                {formatMoney(t.amount)}
              </div>
              <div className='col-span-3 text-xs'>Purchase</div>
              <div className='col-span-3 text-xs'>
                {moment(t.date).format('l')}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

interface Transaction {
  id: string
  amount: number
  details: 'purchase'
  date: Date
}
