import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import createTitle from '../utils/createTitle'

export default () => {
  return (
    <div className='container my-3'>
      <Helmet>
        <title>{createTitle('Terms of Use')}</title>
      </Helmet>
      <h1 className='title mb-3'>Fundraise Club - Terms of Use</h1>
      <p>
        Here at Fundraise Club, we strive to deliver an open, honest, and
        inclusive platform that respects everyone's rights and privacy. Our
        Terms of Use and Privacy Policy documents can be accessed below. If
        you have any questions, please feel free to{' '}
        <Link
          className='Link font-bold hover:underline'
          to={'/contact-us'}
        >
          Contact Us
        </Link>
        .
      </p>
      <div className='mt-6'>
        <a
          className='hover:underline'
          href='/terms.html'
          target='_blank'
          rel='noreferrer noopener'
        >
          Terms of Use - All Users
        </a>
      </div>
      <div className='mt-2'>
        <a
          className='hover:underline'
          href='/charity-terms.html'
          target='_blank'
          rel='noreferrer noopener'
        >
          Terms of Use - Charities
        </a>
      </div>
      <div className='mt-2'>
        <a
          className='hover:underline'
          href='/privacy-policy.html'
          target='_blank'
          rel='noreferrer noopener'
        >
          Privacy Policy - All Users
        </a>
      </div>
    </div>
  )
}
