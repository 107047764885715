import { Link } from 'react-router-dom'

export default () => {
  return (
    <div className='container my-5'>
      <h1 className='text-2xl'>Delete Account</h1>
      <p>
        To delete your account, please{' '}
        <Link className='hover:underline text-blue-600' to='/contact-us'>
          Contact Us
        </Link>
        .
      </p>
    </div>
  )
}
