import { keys, map } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '../atoms/userAtom'
import useCardEndingIn from '../hooks/useCardEndingIn'
import useCartInfo from '../hooks/useCartInfo'
import Campaign from '../types/Campaign'
import Ticket from '../types/Ticket'
import { CartItem } from '../types/User'
import { collections } from '../utils/firebase'
import theme from '../utils/theme'
import InviteModal from './InviteModal/InviteModal'
import icCheckoutFriends from './ic-checkout-friends.svg'
import imgCheckout from './img-checkout-min.webp'

function ReserveButton({
  item: { campaignId, number, ticketId }
}: {
  item: CartItem
}) {
  const [open, setOpen] = useState(false)
  const [addingSyndicate, setAddingSyndicate] = useState(false)
  const [ticket, setTicket] = useState<Ticket>()

  const ref = useMemo(() => {
    return collections.tickets(campaignId).doc(ticketId)
  }, [ticketId, campaignId])

  useEffect(() => {
    ref.onSnapshot(snap => {
      setTicket(snap.data())
    })
  }, [ref])

  if (!ticket) {
    return null
  }

  return (
    <>
      <button
        disabled={addingSyndicate}
        className='button button-yellow'
        onClick={() => {
          if (ticket.syndicate) {
            setOpen(true)
          } else {
            setAddingSyndicate(true)
            ref
              .update({
                syndicate: true
              })
              .catch(e => alert(e.message))
              .finally(() => setAddingSyndicate(false))
          }
        }}
      >
        {addingSyndicate
          ? 'Please wait...'
          : ticket.syndicate
          ? 'Invite'
          : 'Reserve'}
      </button>
      <InviteModal
        dismiss={() => setOpen(false)}
        isOpen={open}
        campaignId={campaignId}
        ticketId={ticketId}
        number={number}
      />
    </>
  )
}

export default () => {
  const { campaigns, cartTotal, groupedByCampaign } = useCartInfo(true)
  const endingIn = useCardEndingIn()
  const user = useRecoilValue(userAtom)

  return (
    <div className=''>
      <img
        src={imgCheckout}
        alt='Checkout Complete'
        className='h-64 object-cover w-full'
      />
      <div className='my-4 container'>
        <div className='text-4xl text-colored'>
          Thank you for your participation
        </div>
        <p className='text-sm text-gray-700 mt-2'>
          You should receive a confirmation email soon. We will notify you
          of the raffle winner by email as well after the raffle is
          completed.
        </p>
        {(!user?.favoriteCategoryIds ||
          keys(user.favoriteCategoryIds).length === 0) && (
          <div className='mt-4'>
            Looks like you haven't selected your favorite raffle categories
            yet!
            <div className='mt-1'>
              <Link to='/portal/settings/notfiications'>
                <button className='button button-outline'>
                  Complete Profile
                </button>
              </Link>
            </div>
          </div>
        )}
        <div className='grid grid-cols-12 gap-4 mt-6'>
          <div className='col-span-12 md:col-span-6'>
            <div className='text-xl'>Order Summary</div>
            {map(groupedByCampaign, (items, campaignId) => {
              const campaign = campaigns[campaignId] as
                | Campaign
                | undefined

              return (
                <div className='shadow bg-white p-3 mt-3'>
                  <div className='font-bold'>{campaign?.title}</div>
                  <div className='flex mt-2'>
                    <div className='flex-1 font-bold text-xs text-gray-400'>
                      NUMBER
                    </div>
                    <div className='flex-1 font-bold text-xs text-gray-400'>
                      BOUGHT
                    </div>
                    <div className='flex-1 font-bold text-xs text-gray-400'>
                      POTENTIAL
                    </div>
                    <div className='flex-1'></div>
                  </div>
                  {items.map(item => {
                    const full =
                      campaign &&
                      item.shares.length >= campaign.sharesPerSpot
                    return (
                      <div className='flex mt-3 items-center'>
                        <div className='flex-1'>
                          <div
                            className='font-bold h-8 w-8 flex items-center justify-center text-white'
                            style={{
                              backgroundColor: full
                                ? theme.board.open
                                : theme.board.partial
                            }}
                          >
                            {item.number}
                          </div>
                        </div>
                        <div className='flex-1'>
                          $
                          {campaign
                            ? item.shares.length * campaign.pricePerShare
                            : '?'}
                        </div>
                        <div className='flex-1'>
                          $
                          {campaign?.prizes[0]
                            ? (item.shares.length /
                                campaign.sharesPerSpot) *
                              campaign.prizes[0].amount
                            : '?'}
                        </div>
                        <div className='flex-1'>
                          {!full && <ReserveButton item={item} />}
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
          <div className='col-span-12 md:col-span-6'>
            <div className='text-xl'>Order Summary</div>
            <div className='mt-3 shadow p-4 bg-white flex items-center justify-between'>
              <div className='text-sm'>
                Paid with Credit Ending in {endingIn}
              </div>
              <div className='text-lg font-bold'>${cartTotal}</div>
            </div>
            <div className='mt-3 p-4 bg-primary text-white'>
              <div className='flex justify-between items-center'>
                <div className='text-2xl mr-3'>
                  Raise with your friends and family
                </div>
                <img
                  src={icCheckoutFriends}
                  alt='Friends and Family'
                  className='w-16 h-16'
                />
              </div>
              <div className='text-sm mt-3'>
                You can reserve the partial raffle number purchases and
                invite your friends to join you in completing your partial
                raffle number orders.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
