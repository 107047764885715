import { useState } from 'react'
import { auth, firestore } from '../utils/firebase'
import TextInput from './TextInput'

export default ({ dismiss }: { dismiss: () => void }) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [expirationDate, setExpirationDate] = useState('')
  const [cardCode, setCardCode] = useState('')
  const [zip, setZip] = useState('')
  const [saving, setSaving] = useState(false)

  const clear = () => {
    setFirstName('')
    setLastName('')
    setCardNumber('')
    setExpirationDate('')
    setCardCode('')
    setZip('')
  }

  return (
    <div>
      <div className='text-xl'>New Payment Method</div>
      <div className='mt-3 grid grid-cols-12 gap-4'>
        <TextInput
          label='First Name'
          className='col-span-12 md:col-span-6'
          value={firstName}
          onTextChange={setFirstName}
        />
        <TextInput
          label='Last Name'
          className='col-span-12 md:col-span-6'
          value={lastName}
          onTextChange={setLastName}
        />
        <TextInput
          label='Credit Card Number'
          className='col-span-12'
          value={cardNumber}
          onTextChange={setCardNumber}
          autoComplete='cc-number'
        />
        <TextInput
          label='Expiration'
          autoComplete='cc-exp'
          className='col-span-12 md:col-span-8'
          value={expirationDate}
          onTextChange={setExpirationDate}
        />
        <TextInput
          label='CVC'
          className='col-span-12 md:col-span-4'
          value={cardCode}
          onTextChange={setCardCode}
          autoComplete='cc-csc'
        />
        <TextInput
          label='Zip Code'
          className='col-span-12 md:col-span-8'
          value={zip}
          onTextChange={setZip}
        />
      </div>
      <div className='mt-4 flex items-center justify-between'>
        <button
          className='button button-outline'
          onClick={() => {
            clear()
            dismiss()
          }}
        >
          Cancel
        </button>
        <button
          disabled={saving}
          className='button button-yellow'
          onClick={() => {
            const user = auth.currentUser
            if (!user) {
              return
            }

            const ref = firestore.collection('cards').doc(user.uid)

            setSaving(true)
            firestore
              .runTransaction(async tx => {
                await ref.set({
                  firstName,
                  lastName,
                  last4: cardNumber.substring(cardNumber.length - 4),
                  expirationDate,
                  cardCode,
                  zip
                })
                await ref.collection('protected').doc('cardNumber').set({
                  cardNumber
                })
              })
              .then(() => {
                clear()
                dismiss()
              })
              .catch(e => {
                alert(e.message)
              })
              .finally(() => {
                setSaving(false)
              })
          }}
        >
          {saving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </div>
  )
}
