import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useRecoilValue } from 'recoil'
import viewerAtom from '../../atoms/viewerAtom'
import createTitle from '../../utils/createTitle'
import { firestore } from '../../utils/firebase'
import NewCardForm from '../NewCardForm'

export default () => {
  const viewer = useRecoilValue(viewerAtom)
  const [open, setOpen] = useState(false)
  const [endingIn, setEndingIn] = useState<string>()

  const ref = useMemo(() => {
    if (!viewer) {
      return
    }
    return firestore.collection('cards').doc(viewer.uid)
  }, [viewer])

  useEffect(() => {
    if (!ref) {
      return
    }

    const unsub = ref.onSnapshot(snap => {
      console.log(snap.data())

      if (!snap.exists) {
        setEndingIn(undefined)
      } else {
        setEndingIn(snap.get('last4'))
      }
    })

    return () => unsub()
  }, [setEndingIn, ref])

  return (
    <div>
      <Helmet>
        <title>{createTitle('Billing')}</title>
      </Helmet>
      <div className='text-2xl'>Billing & Payment</div>
      <div className='mt-4 p-4 bg-white rounded shadow'>
        {open ? (
          <NewCardForm dismiss={() => setOpen(false)} />
        ) : (
          <div>
            <div className='text-xl'>Payment Method</div>
            <div className='flex justify-between items-center'>
              {endingIn && (
                <div className='text-sm mt-2'>
                  Card ending in {endingIn}
                </div>
              )}
            </div>
            <button
              className='mt-2 button button-green'
              onClick={() => {
                setOpen(true)
              }}
            >
              New Card
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
