import { chunk, flatten, uniq } from 'lodash'
import { collections } from './firebase'
import firebase from 'firebase/app'
import { useEffect, useState } from 'react'
import User from '../types/User'

const mem: Record<string, User> = {}

export const useUsers = (ids: string[]) => {
  const [users, setUsers] = useState<User[]>([])

  useEffect(() => {
    const newUsers: User[] = []
    const haveFromMemIds: string[] = []
    ids.forEach(id => {
      if (mem[id]) {
        haveFromMemIds.push(id)
        newUsers.push(mem[id])
      }
    })

    if (newUsers.length > 0) {
      setUsers(newUsers)
    }

    const idsStillNeedToGet = ids.filter(
      id => !haveFromMemIds.includes(id)
    )

    if (idsStillNeedToGet.length > 0) {
      getUsers(idsStillNeedToGet).then(users => {
        setUsers(newUsers.concat(users))
      })
    }
  }, [ids, setUsers])

  return users
}

export default async function getUsers(ids: string[]) {
  const chunks = chunk(uniq(ids), 10)

  const users2d = await Promise.all(
    chunks.map(async idChunk => {
      return collections.users
        .where(firebase.firestore.FieldPath.documentId(), 'in', idChunk)
        .get()
        .then(snap => {
          return snap.docs.map(doc => {
            return {
              id: doc.id,
              ...(doc.data() as Omit<User, 'id'>)
            }
          })
        })
    })
  )

  const users = flatten(users2d)
  users.forEach(user => {
    mem[user.id] = user
  })

  return users
}
