import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import { firestore } from '../../utils/firebase'
import bellOnIcon from './ic-notification-on.svg'
import bellIcon from './ic-notifications.svg'

export default () => {
  const [haveUnread, setHaveUnread] = useState(false)
  const user = useRecoilValue(userAtom)

  useEffect(() => {
    if (!user) {
      return
    }

    const unsub = firestore
      .collection('users')
      .doc(user.id)
      .collection('notifications')
      .where('read', '==', false)
      .onSnapshot(snap => {
        setHaveUnread(!snap.empty)
      })

    return () => unsub()
  }, [user])

  return (
    <img
      className='h-10 w-10 ml-3'
      src={haveUnread ? bellOnIcon : bellIcon}
      alt='Notifications'
    />
  )
}
