import amountIcon from './ic-amount.svg'
import prizeIcon from './ic-prize.svg'
import spotIcon from './ic-spot.svg'

const Item = ({
  title,
  text,
  image
}: {
  title: string
  text: string
  image: string
}) => {
  return (
    <div className='flex items-center mt-4'>
      <img src={image} alt={title} className='h-20 w-20' />
      <div className='ml-2'>
        <div className='text-xs text-primary font-bold'>{title}</div>
        <div className='text-sm mt-1'>{text}</div>
      </div>
    </div>
  )
}

export default () => {
  return (
    <div className='p-4 rounded border border-gray-200 bg-white'>
      <div className='text-xl font-bold '>How it works</div>
      <Item
        title='CHOOSE YOUR SPOT'
        text='Look for open numbers and choose the ones you want'
        image={spotIcon}
      />
      <Item
        title='SELECT THE AMOUNT YOU WANT TO DONATE'
        text='Purchase partial or full spots and see your probability'
        image={amountIcon}
      />
      <Item
        title='FIND OUT RESULTS'
        text='If one of your numbers is picked you will be contacted!'
        image={prizeIcon}
      />
    </div>
  )
}
