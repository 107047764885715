import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import User from '../../types/User'
import createTitle from '../../utils/createTitle'
import { collections } from '../../utils/firebase'

export default () => {
  const [users, setUsers] = useState<User[]>([])
  const [charityState, setCharityState] = useState<
    'approved' | 'rejected' | 'pending'
  >('pending')

  useEffect(() => {
    return collections.users
      .where('charityState', '==', charityState)
      .onSnapshot(snap => {
        setUsers(snap.docs.map(d => d.data()))
      })
  }, [charityState])

  return (
    <div>
      <Helmet>
        <title>{createTitle('Charities')}</title>
      </Helmet>
      <div className='text-2xl'>Charities</div>
      <div className='mt-3'>
        <select
          value={charityState}
          onChange={e => setCharityState(e.target.value as any)}
          className='p-2'
        >
          <option value='pending'>Pending</option>
          <option value='approved'>Approved</option>
          <option value='rejected'>Rejected</option>
        </select>
      </div>
      <div className='grid gap-3 grid-cols-12 mt-4'>
        {users.length === 0 && (
          <div className='col-span-12'>No pending charities</div>
        )}
        {users.map(({ id, charity }) => {
          if (!charity) {
            return null
          }

          const { imageUrl, name } = charity

          return (
            <div
              key={id}
              className='col-span-12 md:col-span-6 lg:col-span-4 p-4 bg-white rounded shadow flex flex-col items-center'
            >
              <img
                src={imageUrl}
                alt={name}
                className='h-14 w-14 rounded object-cover'
              />
              <div className='font-bold text-lg mt-2'>{name}</div>
              <div className='items-center justify-center flex mt-2'>
                <Link
                  to={`/charities/${id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <button className='button button-outline'>View</button>
                </Link>
                <button
                  className='button bg-red-500 button-light ml-2'
                  onClick={() => {
                    if (window.confirm(`Reject ${name}?`)) {
                      collections.users.doc(id).update({
                        charityState: 'rejected'
                      })
                    }
                  }}
                >
                  Reject
                </button>
                <button
                  className='button button-green ml-2'
                  onClick={() => {
                    if (window.confirm(`Approve ${name}?`)) {
                      collections.users.doc(id).update({
                        charityState: 'approved'
                      })
                    }
                  }}
                >
                  Approve
                </button>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
