import { mdiArrowLeft, mdiArrowRight, mdiCheck, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import { range } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useParams } from 'react-router'
import { useCart, useSetCart } from '../../atoms/cartAtom'
import BoardState from '../../types/BoardState'
import Campaign from '../../types/Campaign'
import { collections } from '../../utils/firebase'
import playNowClickBroadcast from '../../utils/playNowClickBroadcast'
import theme from '../../utils/theme'
import TextInput from '../TextInput'

const ReservedUntil = ({ date }: { date: Date }) => {
  const [, setR] = useState(Math.random())

  useEffect(() => {
    const interval = setInterval(() => {
      setR(Math.random())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })

  const m = moment(date)
  const hrs = m.diff(moment(), 'hours')
  const mins = m.diff(moment(), 'minutes') % 60
  const sec = m.diff(moment(), 'seconds') % 60

  return (
    <div>
      {hrs}h:{mins}m:{sec}s
    </div>
  )
}

const LegendItem = ({ color, text }: { color: string; text: string }) => {
  return (
    <div className='flex items-center col-span-6 md:col-span-3'>
      <div
        className='h-6 w-6 rounded'
        style={{ backgroundColor: color }}
      />
      <div className='text-sm ml-2'>{text}</div>
    </div>
  )
}

export default ({
  campaign,
  board
}: {
  campaign: Campaign
  board: BoardState
}) => {
  const params = useParams<{ number?: string }>()
  const paramNumber = !!params.number && parseInt(params.number)

  const [search, setSearch] = useState('')
  const [hundred, setHundred] = useState(0)
  const [zoomed, setZoomed] = useState(campaign.spots <= 100)
  const [modalState, setModalState] = useState<
    | {
        number: number
        step: 'intro' | 'shares' | Date
        shares: number[]
        syndicate: boolean
        fromPlayNow?: boolean
      }
    | undefined
  >(
    paramNumber
      ? {
          number: paramNumber,
          step: 'intro',
          shares: [],
          syndicate: false
        }
      : undefined
  )
  const cart = useCart()
  const setCart = useSetCart()

  useEffect(() => {
    const id: string = playNowClickBroadcast.subscribe(() => {
      if (campaign.startsAt.toMillis() > Date.now()) {
        return alert(
          'This raffle has not started yet, sorry. Come back soon!'
        )
      }
      if (campaign.finishedAt) {
        return alert('This raffle is finished, sorry!')
      }

      const numbers = range(1, campaign.spots)

      for (const number of numbers) {
        if (board.sharesTaken(number) === 0) {
          return setModalState({
            number,
            step: 'intro',
            shares: [],
            syndicate: false,
            fromPlayNow: true
          })
        }
      }
      alert('There are no spots that are fully open!')
    })

    return () => playNowClickBroadcast.unsubscribe(id)
  }, [board, campaign])

  const hundreds: [number, number][] = []
  for (let i = 1; i < campaign.spots; i += 100) {
    hundreds.push([i, i + 99])
  }

  const [start, end] = hundreds[hundred]
  const modalTickets =
    (modalState && board.state[modalState.number]?.tickets) || []

  search.toString()

  return (
    <div className='p-4 rounded border border-gray-200 bg-white'>
      <div className='text-xl font-bold'>Choose your spot(s)</div>
      <TextInput
        className='flex-auto mt-4'
        placeholder='Search a Number'
        onTextChange={setSearch}
      />
      <div className='mt-3 rounded border border-gray-300'>
        <div className='grid grid-cols-12 gap-2 p-2'>
          <LegendItem color={theme.board.open} text='Open' />
          <LegendItem color={theme.board.purchased} text='Purchased' />
          <LegendItem color={theme.board.partial} text='Partially Open' />
          <LegendItem color={theme.board.reserved} text='Reserved' />
        </div>
        <div className='h-px w-full bg-gray-300' />
        {zoomed && (
          <>
            <div className='flex items-center justify-between p-1 bg-gray-100'>
              <div
                className='p-2 cursor-pointer hover:bg-gray-400 rounded-full'
                onClick={() => {
                  if (hundred > 0) setHundred(hundred - 1)
                }}
              >
                <Icon path={mdiArrowLeft} size={0.8} />
              </div>
              <div className='text-2xl font-bold'>
                {start}-{Math.min(end, campaign.spots)}
              </div>
              <div
                className='p-2 cursor-pointer hover:bg-gray-400 rounded-full'
                onClick={() => {
                  if (end < campaign.spots) setHundred(hundred + 1)
                }}
              >
                <Icon path={mdiArrowRight} size={0.8} />
              </div>
            </div>
            <div className='h-px w-full bg-gray-300' />
          </>
        )}
        <div className='p-3'>
          {zoomed ? (
            <div className='grid grid-cols-10 gap-px'>
              {range(start, Math.min(campaign.spots + 1, end + 1)).map(
                number => {
                  const sharesTaken = board.sharesTaken(number)
                  const tickets = board.getTickets(number)
                  const syndicateTicket = tickets.find(t => !!t.syndicate)
                  const syndicateEndsAt =
                    syndicateTicket &&
                    moment(syndicateTicket.createdAt).add(1, 'day')
                  const reserved =
                    syndicateEndsAt && moment().isBefore(syndicateEndsAt)

                  return (
                    <div
                      key={number}
                      onClick={() => {
                        if (campaign.startsAt.toMillis() > Date.now()) {
                          return alert(
                            'This raffle has not started yet, sorry. Come back soon!'
                          )
                        }
                        if (campaign.finishedAt) {
                          return alert('This raffle is finished, sorry!')
                        }

                        if (sharesTaken >= campaign.sharesPerSpot) {
                          return alert(
                            'Sorry, this spot is fully purchased!'
                          )
                        }

                        const reserved =
                          syndicateEndsAt &&
                          moment().isBefore(syndicateEndsAt)
                        let referred = false
                        if (syndicateTicket) {
                          const value = localStorage.getItem(
                            syndicateTicket.id
                          )
                          if (value) {
                            referred = true
                          }
                        }

                        setModalState({
                          number,
                          step:
                            reserved && syndicateEndsAt && !referred
                              ? syndicateEndsAt.toDate()
                              : 'intro',
                          shares: [],
                          syndicate: false
                        })
                      }}
                      className='col-span-2 md:col-span-1 flex flex-1 h-12 justify-center bg-gray-200 items-center text-white font-bold cursor-pointer'
                      style={{
                        backgroundColor:
                          sharesTaken === 0
                            ? theme.board.open
                            : sharesTaken >= campaign.sharesPerSpot
                            ? theme.board.purchased
                            : reserved
                            ? theme.board.reserved
                            : theme.board.partial
                      }}
                    >
                      {number}
                    </div>
                  )
                }
              )}
            </div>
          ) : (
            <div className='grid grid-cols-12 gap-px'>
              {hundreds.map(([start, end], index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setHundred(index)
                      setZoomed(true)
                    }}
                    className='hover:bg-green-500 cursor-pointer text-white text-lg font-bold col-span-6 lg:col-span-4 xl:col-span-3 flex items-center justify-center p-10 white bg-primary'
                  >
                    {start}-{Math.min(end, campaign.spots)}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={!!modalState}
        onRequestClose={() => setModalState(undefined)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            margin: 0,
            padding: 0,
            width: '80%',
            maxWidth: 500
          }
        }}
      >
        <div className='bg-white'>
          {modalState?.step === 'intro' && (
            <div className='p-6'>
              <div className='text-center'>Spot #{modalState.number}</div>
              <div className='text-center text-6xl font-bold mt-2'>
                {modalState?.number}
              </div>
              {modalState.fromPlayNow && (
                <div className='text-center text-sm'>
                  (We selected the next available fully open ticket. To
                  choose a different number, you can click any number on
                  the board below.)
                </div>
              )}
              <div className='grid grid-cols-2 mt-4 gap-3'>
                <button
                  className='col-span-1 button button-green'
                  disabled={modalTickets.length > 0}
                  onClick={() => {
                    if (!modalState) {
                      return
                    }

                    setModalState({
                      number: modalState.number,
                      step: 'shares',
                      shares: range(1, campaign.sharesPerSpot + 1),
                      syndicate: false
                    })
                  }}
                >
                  BUY FULL SPOT
                </button>
                <button
                  className='col-span-1 button button-outline'
                  onClick={() => {
                    if (!modalState) {
                      return
                    }

                    setModalState({
                      number: modalState.number,
                      step: 'shares',
                      shares: [],
                      syndicate: false
                    })
                  }}
                >
                  GET YOUR SHARES
                </button>
              </div>
            </div>
          )}
          {modalState?.step === 'shares' && (
            <div className='p-6'>
              <div>
                <div className='text-xs text-primary text-center'>
                  Spot #{modalState.number}
                </div>
                <div className='mt-1 text-2xl text-center'>
                  {modalState.shares.length === campaign.sharesPerSpot
                    ? 'Buy Full Spot'
                    : 'Get Your Shares'}
                </div>
                <div className='flex items-center mt-2 justify-center'>
                  <div className='text-xs'>Price per share</div>
                  <div className='ml-2 text-xs font-bold'>
                    ${campaign.pricePerShare}
                  </div>
                </div>
                <div className='grid grid-cols-10 gap-px mt-3'>
                  {range(1, campaign.sharesPerSpot + 1).map(share => {
                    const selected = modalState.shares.includes(share)
                    const taken = !!modalTickets.find(t =>
                      t.shares.includes(share)
                    )
                    return (
                      <div
                        key={modalState.number + '-' + share}
                        onClick={() => {
                          if (taken) {
                            return
                          }
                          if (selected) {
                            setModalState({
                              number: modalState.number,
                              step: 'shares',
                              shares: modalState.shares.filter(s => {
                                return s !== share
                              }),
                              syndicate: modalState.syndicate
                            })
                          } else {
                            setModalState({
                              number: modalState.number,
                              step: 'shares',
                              shares: modalState.shares.concat(share),
                              syndicate: modalState.syndicate
                            })
                          }
                        }}
                        className={`col-span-2 md:col-span-1 flex h-12 items-center justify-center ${
                          taken
                            ? 'bg-dark-blue'
                            : selected
                            ? 'bg-primary'
                            : 'bg-gray-200'
                        } ${taken ? '' : 'cursor-pointer'}`}
                      >
                        {!taken && (
                          <Icon
                            path={selected ? mdiCheck : mdiPlus}
                            color={selected ? 'white' : theme.primary}
                            size={1}
                          />
                        )}
                      </div>
                    )
                  })}
                </div>
                <div
                  className='text-center text-xs underline text-gray-700 mt-3 cursor-pointer'
                  onClick={() => {
                    if (modalState.shares.length > 0) {
                      setModalState({
                        number: modalState.number,
                        step: 'shares',
                        shares: [],
                        syndicate: false
                      })
                    } else {
                      setModalState({
                        number: modalState.number,
                        step: 'shares',
                        shares: range(1, campaign.sharesPerSpot + 1),
                        syndicate: false
                      })
                    }
                  }}
                >
                  {modalState.shares.length >= campaign.sharesPerSpot
                    ? 'Or get shares instead'
                    : 'Or buy full spot instead'}
                </div>
              </div>
              <div className='p-4 bg-gray-200 mt-3'>
                <div className='grid gap-4 grid-cols-2'>
                  <div className='flex items-center col-span-2 md:col-span-1'>
                    <div className='text-xs text-gray-700'>
                      Your total shares
                    </div>
                    <div className='ml-2 text-xs font-bold'>
                      {modalState.shares.length}
                    </div>
                  </div>
                  <div className='flex items-center col-span-2 md:col-span-1'>
                    <div className='text-xs text-gray-700'>
                      Donation Amount
                    </div>
                    <div className='ml-2 text-xs font-bold'>
                      ${modalState.shares.length * campaign.pricePerShare}
                    </div>
                  </div>
                  {modalState.shares.length < campaign.sharesPerSpot && (
                    <div className='flex items-center col-span-2 md:col-span-1'>
                      <div className='text-xs text-gray-700'>
                        Shares left
                      </div>
                      <div className='ml-2 text-xs font-bold'>
                        {(() => {
                          let sharesTaken =
                            modalState.shares.length +
                            board.sharesTaken(modalState.number)

                          return `${
                            campaign.sharesPerSpot - sharesTaken
                          }/${campaign.sharesPerSpot}`
                        })()}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='mt-4 grid gap-4 grid-cols-2'>
                <div className='col-span-1'>
                  {board.sharesTaken(modalState.number) === 0 &&
                    modalState.shares.length < campaign.sharesPerSpot && (
                      <>
                        <div className='text-xs text-primary font-bold'>
                          FIRST PLAYER BONUS
                        </div>
                        <input
                          type='checkbox'
                          id='syndicate-checkbox'
                          checked={modalState.syndicate}
                          onChange={e => {
                            setModalState({
                              ...modalState,
                              syndicate: e.target.checked
                            })
                          }}
                        />
                        <label
                          htmlFor='syndicate-checkbox'
                          className='ml-2'
                        >
                          Create Syndicate
                        </label>
                        <div className='text-xs'>
                          Reserve this spot for 24 hours and share with
                          friends
                        </div>
                      </>
                    )}
                </div>
                <div className='col-span-1'>
                  <div className='text-sm'>Potential</div>
                  <div className='text-2xl font-bold'>
                    $
                    {campaign.prizes[0]
                      ? campaign.prizes[0].amount *
                        (modalState.shares.length / campaign.sharesPerSpot)
                      : 0}
                  </div>
                  <div className='text-sm'>
                    (Chance: 1 in {campaign.spots})
                  </div>
                </div>
              </div>
              <div className='grid gap-4 grid-cols-2 mt-4'>
                <button
                  className='button button-outline col-span-1'
                  onClick={() => {
                    setModalState(undefined)
                  }}
                >
                  BACK TO BOARD
                </button>
                <button
                  className='button button-green col-span-1'
                  onClick={() => {
                    setCart([
                      ...cart,
                      {
                        campaignId: campaign.id,
                        shares: modalState.shares,
                        number: modalState.number,
                        syndicate: modalState.syndicate,
                        ticketId: collections.tickets(campaign.id).doc().id
                      }
                    ])

                    setModalState(undefined)
                  }}
                >
                  Add to Cart
                </button>
              </div>
            </div>
          )}
          {modalState?.step instanceof Date && (
            <div
              style={{ backgroundColor: theme.board.reserved }}
              className='flex flex-col items-center p-6'
            >
              <div>Spot #</div>
              <div className='mt-4 text-5xl font-bold'>
                {modalState.number}
              </div>
              <div className='text-xl'>Reserved</div>
              <div className='mt-3 text-center'>
                Sorry, somebody bought part of this number, and reserved
                the rest of the spot. It will become available to the
                public in:
              </div>
              <ReservedUntil date={modalState.step} />
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}
