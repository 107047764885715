import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { auth } from '../utils/firebase'
import TextInput from './TextInput'

export default () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  return (
    <div className='container py-5 flex flex-col items-center'>
      <div className='flex items-center text-sm'>
        <div className='mr-2'>I remembered my password.</div>
        <Link className='Link' to='/sign-in'>
          <div className='font-bold hover:underline'>Sign In Now</div>
        </Link>
      </div>
      <div className='mt-4 text-colored text-5xl font-bold'>
        Forgot your password?
      </div>
      <div className='mt-2 text-sm'>
        Type in your email and we'll send you a password reset link
      </div>
      <div
        className='mt-5 p-5 rounded shadow bg-white flex flex-col items-center'
        style={{ minWidth: 350 }}
      >
        <TextInput
          label='Email'
          type='email'
          className='w-full'
          value={email}
          onTextChange={setEmail}
        />
        <button
          disabled={loading}
          className='button button-green mt-3 w-full'
          onClick={() => {
            setLoading(true)
            auth
              .sendPasswordResetEmail(email)
              .then(() => {
                history.push('/forgot-password-sent')
              })
              .catch(e => {
                alert(e.message)
              })
              .finally(() => {
                setLoading(false)
              })
          }}
        >
          {loading ? 'Loading...' : 'Reset Password'}
        </button>
      </div>
    </div>
  )
}
