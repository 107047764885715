import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import constants from '../utils/constants'
import theme from '../utils/theme'
import cards from './cards.jpg'
import logoWhite from './logo-footer.png'
import nameWhite from './logo-white.png'

const FooterLink: FunctionComponent<{ to: string }> = ({
  to,
  children
}) => {
  return (
    <Link to={to}>
      <div className='text-white hover:underline'>{children}</div>
    </Link>
  )
}

export default () => {
  return (
    <footer
      className='footer text-white'
      style={{ backgroundColor: theme.darkBackground }}
    >
      <div className='container my-4 grid gap-4 grid-cols-12'>
        <div className='mt-3 col-span-12 md:col-span-6 lg:col-span-4 flex justify-center md:justify-start'>
          <img
            src={nameWhite}
            alt='Fundraise Club'
            style={{ height: 80 }}
          />
        </div>
        <div className='mt-3 col-span-12 md:col-span-6 lg:col-span-4'>
          <div className='grid grid-cols-12 gap-4'>
            <div className='col-span-6'>
              {/* <FooterLink to='/about'>What is Fundraise Club?</FooterLink> */}
              <FooterLink to='/portal/dashboard'>My Portal</FooterLink>
              <FooterLink to='/campaigns'>Fundraisers</FooterLink>
            </div>
            <div className='col-span-6'>
              <FooterLink to='/portal/settings/payment'>
                Payment Options
              </FooterLink>
              <FooterLink to='/terms'>Terms of Use</FooterLink>
              <FooterLink to='/contact-us'>Customer Support</FooterLink>
            </div>
          </div>
        </div>
        <div className='mt-3 flex flex-col items-center col-span-12 lg:col-span-4'>
          <img
            src={logoWhite}
            alt='Fundraise Club'
            style={{ height: 80 }}
          />
          <div className='text-center text-sm mt-3'>
            Copyright © {new Date().getFullYear()} - {constants.webURL} -
            All Rights Reserved
          </div>
          <img
            alt='Credit Card Logos'
            className='mt-3'
            src={cards}
            style={{ height: 24 }}
          />
        </div>
      </div>
    </footer>
  )
}
