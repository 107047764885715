import Campaign from '../types/Campaign'

export default (campaign: Campaign) => {
  const started = campaign.startsAt.toDate() < new Date()
  const finished = !!campaign.finishedAt

  return {
    finished,
    started,
    live: started && !finished
  }
}
