import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Campaigns from '../Campaigns'
import SectionHeader from '../SectionHeader'
import HaveQuestions from './HaveQuestions'
import Jumbotron from './Jumbotron'
import Testimonials from './Testimonials'
import win123 from './img-get-started.png'

export default () => {
  return (
    <div className='bg-white'>
      <Jumbotron />
      <div className='container'>
        <div className='mt-5'></div>
        <Helmet>
          <title>Fundraise Club - Where Fundraising Is Real</title>
        </Helmet>
        <div className='mt-4'></div>
        <SectionHeader>Real charity. Real fundraising.</SectionHeader>
        <Link to='/campaigns?t=live'>
          <img src={win123} alt='Raise. 1-2-3!' className='mt-4' />
        </Link>
        <div className='mt-5'></div>
        <SectionHeader>Live Boards</SectionHeader>
        <div className='mt-4'></div>
        <Campaigns limit={4} />
        <div className='mt-5'></div>
      </div>
      <div className='mt-5'></div>
      <HaveQuestions />
      <div className='mt-5'></div>
      <Testimonials />
    </div>
  )
}
