import { Link } from 'react-router-dom'
import Slider from '../Slider'
import Campaign from '../types/Campaign'
import campaignState from '../utils/campaignState'
import formatMoney from '../utils/formatMoney'
import getCountdown from '../utils/getCountdown'

export default ({ campaigns }: { campaigns: Campaign[] }) => {
  return (
    <div className='grid grid-cols-12 gap-5'>
      {campaigns.map(campaign => {
        const { imageUrl, participants, charity } = campaign
        const { started } = campaignState(campaign)
        const { days, hours } = getCountdown(campaign)
        return (
          <Link
            to={`/campaigns/${campaign.id}`}
            key={campaign.id}
            className='card bg-white col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3'
          >
            <img
              style={{ height: 120, width: '100%', objectFit: 'cover' }}
              src={imageUrl}
              alt={campaign.title}
            />
            <div className='p-4'>
              <div className='flex flex-col mb-3 items-center'>
                <div className='text-xs text-gray-700'>Up to</div>
                <div className='text-2xl text-colored font-bold'>
                  {formatMoney(campaign.prizes[0]?.amount || 0)}
                </div>
                <div className='text-xs text-gray-700'>
                  Only {participants}{' '}
                  {participants === 1 ? 'Player' : 'Players'}
                </div>
              </div>
              <Slider
                max={campaign.spots}
                current={campaign.spots - campaign.spotsAvailable}
              />
              <div className='flex items-center mt-1'>
                <div className='text-lg text-colored'>
                  {campaign.spotsAvailable}
                </div>
                <div className='text-xs ml-2 text-gray-700'>
                  Spots Left
                </div>
              </div>
              <div className='mt-2 font-bold text-xl'>
                {campaign.title}
              </div>
              {charity && (
                <div className='flex items-center justify-between'>
                  <div>{charity.name}</div>
                  <img
                    className='rounded-lg h-12 object-cover'
                    src={charity.imageUrl}
                    alt={charity.name}
                  />
                </div>
              )}
              <div className='mt-3 flex-col flex items-center'>
                {!started && (
                  <>
                    <div className='text-xl text-colored font-bold'>
                      {days > 0 ? days : hours}
                    </div>
                    <div className='text-sm text-colored'>
                      {days > 0 ? 'Days' : 'Hours'} Left
                    </div>
                  </>
                )}
                <button className='button button-yellow mt-2 font-bold px-8'>
                  Get Started
                </button>
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}
