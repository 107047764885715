import { atom } from 'recoil'
import Platform from '../types/Platform'
import { firestore } from '../utils/firebase'

export const platformDoc = firestore.doc('platform/0')

export default atom<Platform>({
  key: 'platformState',
  default: {
    commission: 0.1,
    categories: {},
    faqs: []
  }
})
