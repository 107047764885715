import React from 'react'

export default ({
  onTextChange,
  label,
  className,
  inputRef,
  required,
  ...rest
}: {
  onTextChange?: (value: string) => void
  label?: string
  inputRef?: any
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) => {
  return (
    <div className={className}>
      {label && (
        <div className='mb-1 text-xs text-gray-500'>
          {label}
          {required ? <span className='text-red-600'> *</span> : null}
        </div>
      )}
      <input
        ref={inputRef}
        className='text-input'
        onChange={e => {
          onTextChange?.(e.target.value)
        }}
        required={required}
        {...rest}
      />
    </div>
  )
}
