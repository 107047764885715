import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import BoardState from '../../types/BoardState'
import Campaign from '../../types/Campaign'
import Charity from '../../types/Charity'
import Ticket from '../../types/Ticket'
import createTitle from '../../utils/createTitle'
import { collections } from '../../utils/firebase'
import BottomContent from './BottomContent'
import CountdownBar from './CountdownBar'
import TopContent from './TopContent'

export default () => {
  const { id } = useParams<{ id: string }>()
  const [campaign, setCampaign] = useState<Campaign>()
  const [charity, setCharity] = useState<Charity>()
  const [tickets, setTickets] = useState<Ticket[]>([])
  const [board, setBoard] = useState<BoardState>()

  useEffect(() => {
    const unsubCampaign = collections.campaigns
      .doc(id)
      .onSnapshot(snap => {
        setCampaign(snap.data())
      })

    const unsubTickets = collections.tickets(id).onSnapshot(snap => {
      setTickets(snap.docs.map(d => d.data()))
    })

    return () => {
      unsubCampaign()
      unsubTickets()
    }
  }, [id, setCampaign, setTickets])

  useEffect(() => {
    if (!campaign) {
      return
    }

    const unsub = collections.users.doc(campaign.uid).onSnapshot(snap => {
      const user = snap.data()
      if (user) {
        setCharity(user.charity)
      }
    })

    return () => unsub()
  }, [campaign, setCharity])

  useEffect(() => {
    if (campaign && tickets) {
      setBoard(new BoardState(tickets, campaign))
    }
  }, [campaign, tickets])

  if (campaign) {
    campaign.charity = charity
  }

  return campaign ? (
    <>
      <Helmet>
        <title>{createTitle(campaign.title)}</title>
        <meta property='description' content={campaign.description} />
      </Helmet>
      <CountdownBar campaign={campaign} />
      <div className='container my-4'>
        <TopContent campaign={campaign} board={board} />
      </div>
      <div style={{ backgroundColor: '#eceff1' }}>
        <BottomContent
          campaign={campaign}
          tickets={tickets}
          board={board}
        />
      </div>
    </>
  ) : null
}
