import theme from '../../utils/theme'

const testimonials = [
  {
    text: 'Fundraise Club completely redefined our charity campaign! In a flash, we were able to receive the funds needed, and people were having so much fun giving their part!',
    by: 'David Landau, Achim Together'
  },
  {
    text: "I just finished contributing to the three different campaigns! Don't think I've even had such a good time giving Tzedakah before...",
    by: 'Estelle Lieber'
  },
  {
    text: "This is the first time in my life that I've actually won something! Fundraise Club gave me the chance to express my playful streak, while allowing me to easily donate to my favorite charity cause",
    by: 'Abraham Farkas'
  }
]

export default () => {
  return (
    <div
      style={{
        backgroundColor: theme.primaryLightBackground,
        paddingTop: 30,
        paddingBottom: 30
      }}
    >
      <div className='container'>
        <div className='flex flex-col items-center px-5'>
          <div className='text-4xl text-colored font-bold'>
            Giving is our priority
          </div>
          <div className='text-colored text-lg mt-2'>
            It's big. It's real. It's live.
          </div>
        </div>
        <div className='mt-4 grid grid-cols-12 gap-4'>
          {testimonials.map((testimonial, index) => {
            return (
              <div
                key={index}
                className='col-span-12 md:col-span-4 card bg-white p-4 flex flex-col justify-between'
              >
                <div>"{testimonial.text}"</div>
                <div className='mt-4 text-colored'>- {testimonial.by}</div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
