import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import Icon from '@mdi/react'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import usePlatform from '../../hooks/usePlatform'
import createTitle from '../../utils/createTitle'

export default () => {
  const [openIndex, setOpenIndex] = useState<number>()
  const { faqs } = usePlatform()

  return (
    <div className='container my-4'>
      <Helmet>
        <title>{createTitle('Frequently Asked Questions')}</title>
      </Helmet>
      <div className='grid grid-cols-12 gap-3'>
        <h1 className='title col-span-12'>Frequently Asked Questions</h1>
        {faqs.map((faq, index) => {
          const open = index === openIndex
          return (
            <div
              onClick={() => {
                if (open) {
                  setOpenIndex(undefined)
                } else {
                  setOpenIndex(index)
                }
              }}
              key={index}
              className='col-span-12 cursor-pointer hover:bg-gray-100 bg-white rounded shadow flex items-center p-4'
            >
              <div className='flex-1'>
                <p className='text-lg'>{faq.question}</p>
                {open && <p className='text-sm mt-2'>{faq.answer}</p>}
              </div>
              <div
                className={[
                  'flex rounded-full h-9 w-9 items-center justify-center',
                  open ? 'bg-yellow-500' : 'bg-gray-300'
                ].join(' ')}
              >
                <Icon
                  path={open ? mdiChevronUp : mdiChevronDown}
                  color={open ? 'white' : 'black'}
                  size={0.8}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
