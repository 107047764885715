import { mdiDelete } from '@mdi/js'
import Icon from '@mdi/react'
import { keys, orderBy } from 'lodash'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import Slider from '../../Slider'
import { useCart, useSetCart } from '../../atoms/cartAtom'
import userAtom from '../../atoms/userAtom'
import useCartInfo from '../../hooks/useCartInfo'
import Campaign from '../../types/Campaign'
import theme from '../../utils/theme'

export default () => {
  const user = useRecoilValue(userAtom)
  const { campaigns, cartTotal, groupedByCampaign, numNumbers } =
    useCartInfo()
  const cart = useCart()
  const setCart = useSetCart()

  return (
    <div className='container my-4'>
      <h1 className='font-bold text-4xl text-colored'>My Cart</h1>
      <div className='grid gap-5 grid-cols-12 mt-4'>
        <div className='col-span-12 md:col-span-6 grid grid-cols-1 gap-5'>
          {cart.length === 0 ? (
            <div>Your cart is empty!</div>
          ) : (
            cart.map((item, index) => {
              const campaign = campaigns[item.campaignId] as
                | Campaign
                | undefined

              const topPrize = !campaign
                ? undefined
                : orderBy(campaign.prizes, 'amount', 'desc')[0]?.amount

              return (
                <div className='rounded shadow bg-white overflow-hidden'>
                  <div className='relative'>
                    <img
                      src={campaign?.imageUrl}
                      alt={campaign?.title}
                      className='h-56 w-full object-cover'
                    />
                    <button
                      onClick={() => {
                        if (
                          user &&
                          window.confirm(
                            'Remove this item from your cart?'
                          )
                        ) {
                          setCart([
                            ...cart.slice(0, index),
                            ...cart.slice(index + 1)
                          ])
                        }
                      }}
                      className='bg-gray-600 hover:bg-gray-400 absolute top-3 left-3 w-10 h-10 rounded items-center justify-center flex z-20 text-white'
                    >
                      <Icon path={mdiDelete} size={1} color='white' />
                    </button>
                    <div
                      style={{ backgroundColor: theme.board.open }}
                      className='absolute top-3 right-3 w-10 h-10 rounded items-center justify-center flex z-20 text-white'
                    >
                      {item.number}
                    </div>
                  </div>

                  <div className='p-4'>
                    <div className='text-lg'>{campaign?.title}</div>
                    <div className='mt-2'>
                      <Slider
                        current={item.shares.length}
                        max={campaign?.sharesPerSpot || 10}
                      />
                    </div>
                    <div className='mt-4'>
                      <div className='flex justify-between items-center mt-2'>
                        <div className='text-xs text-gray-700 font-bold'>
                          Full Ticket Cost
                        </div>
                        <div className='text-sm font-bold'>
                          $
                          {!campaign
                            ? '?'
                            : campaign.pricePerShare *
                              campaign.sharesPerSpot}
                        </div>
                      </div>
                      <div className='flex justify-between items-center mt-2'>
                        <div className='text-xs text-gray-700 font-bold'>
                          Buying
                        </div>
                        <div className='text-sm font-bold'>
                          $
                          {!campaign
                            ? ''
                            : campaign.pricePerShare * item.shares.length}
                        </div>
                      </div>
                      <div className='flex justify-between items-center mt-2'>
                        <div className='text-xs text-gray-700 font-bold'>
                          Potential
                        </div>
                        <div className='text-sm font-bold'>
                          $
                          {!topPrize || !campaign
                            ? 'N/A'
                            : topPrize *
                              (item.shares.length /
                                campaign.sharesPerSpot)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          )}
        </div>
        {cart.length > 0 && (
          <div className='col-span-12 md:col-span-6'>
            <div className='text-xl'>Order Summary</div>
            <div className='mt-3 bg-white shadow rounded p-3'>
              <div className='flex items-center justify-between'>
                <div>
                  Campaigns: {keys(groupedByCampaign).length}, Numbers:{' '}
                  {numNumbers}
                </div>
                <div className='font-bold text-sm'>${cartTotal}</div>
              </div>
            </div>
            <Link className='Link mt-3' to='/checkout'>
              <button className='button button-yellow mt-3 w-full'>
                Checkout
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
