import { firestore } from './firebase'

export async function triggerEmail(data: {
  from?: string
  replyTo?: string
  to?: To
  cc?: To
  bcc?: To
  toUids?: string[]
  ccUids?: string[]
  bccUids?: string[]
  message: {
    subject?: string
    text?: string
    html?: string
    messageId?: string
    amp?: string
    attachments?: any
  }
}) {
  return firestore.collection('mail').add(data)
}

type To = string | string[]
