import faker from 'faker'
import { range } from 'lodash'
import { Col, Row } from 'react-grid-system'

const items = range(0, 3).map(() => {
  return {
    title: faker.lorem.lines(1),
    text: faker.lorem.paragraph(6),
    image: faker.image.nature() + '?random=' + Math.random()
  }
})

export default () => {
  return (
    <div className='container my-3'>
      <h1 className='title text-center mb-3'>About Us</h1>
      {items.map((item, index) => {
        const even = index % 2 === 0
        return (
          <Row key={index} direction={even ? 'row' : 'row-reverse'}>
            <Col xs={12} md={6} className='mt-4'>
              <h3 className='text-lg'>{item.title}</h3>
              <p className='text-sm'>{item.text}</p>
            </Col>
            <Col xs={12} md={6} className='mt-4'>
              <img
                className='rounded-2xl w-full h-50 object-cover'
                src={item.image}
                alt={item.title}
              />
            </Col>
          </Row>
        )
      })}
    </div>
  )
}
