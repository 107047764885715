import { mdiDelete } from '@mdi/js'
import Icon from '@mdi/react'
import firebase from 'firebase/app'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { platformDoc } from '../../atoms/platformState'
import usePlatform from '../../hooks/usePlatform'
import FAQ from '../../types/FAQ'
import createTitle from '../../utils/createTitle'
import TextInput from '../TextInput'

const Item = ({ faq }: { faq: FAQ }) => {
  return (
    <div className='bg-white rounded shadow p-4 col-span-1 flex items-center justify-between'>
      <div>
        <div className='font-bold'>{faq.question}</div>
        <div className='text-sm'>{faq.answer}</div>
      </div>
      <div
        className='rounded-full hover:bg-gray-100 p-2 cursor-pointer'
        onClick={() => {
          if (window.confirm('Delete this FAQ?')) {
            platformDoc.update({
              faqs: firebase.firestore.FieldValue.arrayRemove(faq)
            })
          }
        }}
      >
        <Icon path={mdiDelete} size={1} color='#777' />
      </div>
    </div>
  )
}

const Input = () => {
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  return (
    <div className='mt-4 bg-white rounded shadow p-4'>
      <TextInput
        label='Question'
        value={question}
        onTextChange={setQuestion}
      />
      <TextInput
        className='mt-2'
        label='Answer'
        value={answer}
        onTextChange={setAnswer}
      />
      <button
        className='button button-green mt-4'
        onClick={() => {
          platformDoc.update({
            faqs: firebase.firestore.FieldValue.arrayUnion({
              question,
              answer
            })
          })
          setQuestion('')
          setAnswer('')
        }}
      >
        Add FAQ
      </button>
    </div>
  )
}

export default () => {
  const { faqs } = usePlatform()

  return (
    <div>
      <Helmet>
        <title>{createTitle("FAQ's")}</title>
      </Helmet>
      <div className='text-2xl'>FAQ's</div>
      <div className='grid grid-cols-1 gap-3 mt-4'>
        {faqs.map(faq => {
          return <Item key={faq.question} faq={faq} />
        })}
      </div>
      <Input />
    </div>
  )
}
