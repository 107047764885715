import { isInteger } from 'lodash'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { platformDoc } from '../../atoms/platformState'
import usePlatform from '../../hooks/usePlatform'
import createTitle from '../../utils/createTitle'
import TextInput from '../TextInput'

export default () => {
  const history = useHistory()
  const platform = usePlatform()
  const [commission, setCommission] = useState('')

  useEffect(() => {
    setCommission((platform.commission * 100).toString())
  }, [platform])

  return (
    <div>
      <Helmet>
        <title>{createTitle('Settings')}</title>
      </Helmet>
      <div className='text-2xl'>Settings</div>
      <div className='mt-3 p-4 rounded bg-white shadow'>
        <TextInput
          label='Commission %'
          value={commission}
          onTextChange={setCommission}
        />
      </div>
      <div className='mt-3 flex items-center justify-between'>
        <button className='button button-outline' onClick={history.goBack}>
          Cancel
        </button>
        <button
          className='button button-green'
          onClick={() => {
            const f = parseFloat(commission)
            if (!isInteger(f)) {
              return alert('Commission be a full integer')
            }

            platformDoc.update({
              commission: f / 100
            })

            history.goBack()
          }}
        >
          Save
        </button>
      </div>
    </div>
  )
}
