import clsx from 'clsx'
import { startCase, uniq } from 'lodash'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import Campaign from '../../types/Campaign'
import Payout from '../../types/Payout'
import Ticket from '../../types/Ticket'
import User from '../../types/User'
import { collections, firestore } from '../../utils/firebase'

const Item = ({
  payout,
  user,
  campaign,
  index
}: {
  payout: Payout
  user?: User
  campaign?: Campaign
  ticket?: Ticket
  index: number
}) => {
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(payout.status)
  const name = user ? user.firstName + ' ' + user.lastName : '?'
  return (
    <div
      className={clsx(
        'grid grid-cols-12 gap-3 items-center p-2',
        index % 2 === 0 && 'bg-gray-100'
      )}
      key={payout.id}
    >
      <div className='col-span-4 md:col-span-2 text-xs'>{name}</div>
      <div className='col-span-4 md:col-span-2 text-xs'>
        {campaign ? campaign.title : '?'}
      </div>
      <div className='col-span-4 md:col-span-2 text-xs'>
        ${payout.amount}
      </div>
      <div className='col-span-4 md:col-span-2 text-xs'>
        {payout.description}
      </div>
      <div className='col-span-4 md:col-span-2 text-xs'>
        {startCase(payout.status)}
      </div>
      <div className='col-span-4 md:col-span-2'>
        <button
          disabled={loading}
          onClick={() => {
            setSelectedStatus(payout.status)
            setModalOpen(true)
          }}
          className='button button-outline px-2 py-1 text-xs'
        >
          Change Status
        </button>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            margin: 0,
            padding: 0,
            width: '80%',
            maxWidth: 500
          }
        }}
      >
        <div className='bg-white p-5'>
          <h3 className='text-xl'>Change Payout Status</h3>
          <div className='col-span-4 md:col-span-2 text-xs'>
            Name: {name}
          </div>
          <div className='text-xs'>
            Campaign: {campaign ? campaign.title : '?'}
          </div>
          <div className='text-xs'>Amount: ${payout.amount}</div>
          <div className='text-xs'>Description: {payout.description}</div>
          <div className='mt-4'></div>
          <fieldset>
            <legend>Status:</legend>
            <div>
              <input
                type='radio'
                id='radio-pending'
                checked={selectedStatus === 'pending'}
                onClick={() => {
                  setSelectedStatus('pending')
                }}
              />
              <label className='ml-2' htmlFor='radio-pending'>
                Pending
              </label>
            </div>
            <div>
              <input
                type='radio'
                id='radio-sent'
                checked={selectedStatus === 'sent'}
                onClick={() => {
                  setSelectedStatus('sent')
                }}
              />
              <label className='ml-2' htmlFor='radio-sent'>
                Sent
              </label>
            </div>
            <div>
              <input
                type='radio'
                id='radio-canceled'
                checked={selectedStatus === 'canceled'}
                onClick={() => {
                  setSelectedStatus('canceled')
                }}
              />
              <label className='ml-2' htmlFor='radio-canceled'>
                Canceled
              </label>
            </div>
          </fieldset>
          <div className='mt-3 flex justify-end items-center'>
            <button
              disabled={loading}
              onClick={() => {
                setModalOpen(false)
              }}
              className='button button-outline px-4 py-2'
            >
              {loading ? 'Please wait...' : 'Change Status'}
            </button>
            <button
              disabled={loading}
              onClick={async () => {
                setLoading(true)
                try {
                  await firestore
                    .collection('payouts')
                    .doc(payout.id)
                    .update({
                      status: selectedStatus
                    })
                  window.alert(
                    `Successfully changed payout status to: ${startCase(
                      selectedStatus
                    )}`
                  )
                } catch (e) {
                  window.alert(e)
                } finally {
                  setModalOpen(false)
                }
              }}
              className='button button-green px-4 py-2 ml-3'
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

type Status = Payout['status']
const allFilters: Status[] = ['pending', 'sent', 'canceled']

export default () => {
  const [payouts, setPayouts] = useState<Payout[]>([])
  const [filter, setFilter] = useState<Status>('pending')
  const [afterDate] = useState<Date>()
  const [beforeDate] = useState<Date>()
  const [campaigns, setCampaigns] = useState<Record<string, Campaign>>({})
  const [tickets, setTickets] = useState<Record<string, Ticket>>({})
  const [users, setUsers] = useState<Record<string, User>>({})

  useEffect(() => {
    setPayouts([])
    let query = firestore
      .collection('payouts')
      .where('status', '==', filter)
      .orderBy('createdAt', 'desc')
    if (afterDate) {
      query = query.where('createdAt', '>=', afterDate)
    }
    if (beforeDate) {
      query = query.where('createdAt', '<=', beforeDate)
    }

    return query.onSnapshot(snap => {
      setPayouts(
        snap.docs.map(d => {
          return {
            ...(d.data() as Omit<Payout, 'id'>),
            id: d.id
          }
        })
      )
    })
  }, [filter, beforeDate, afterDate, setPayouts])

  useEffect(() => {
    const campaignIds = uniq(payouts.map(p => p.campaignId))
    const userIds = uniq(payouts.map(p => p.uid))
    const newCampaigns: Record<string, Campaign> = {}
    Promise.all(
      campaignIds.map(campaignId => {
        return collections.campaigns.doc(campaignId).get()
      })
    ).then(docs => {
      docs.forEach(doc => {
        const campaign = doc.data()
        if (campaign) {
          newCampaigns[doc.id] = campaign
        }
      })
      setCampaigns(newCampaigns)
    })

    const newUsers: Record<string, User> = {}
    Promise.all(
      userIds.map(uid => {
        return collections.users.doc(uid).get()
      })
    ).then(docs => {
      docs.forEach(doc => {
        const user = doc.data()
        if (user) {
          newUsers[doc.id] = user
        }
      })
      setUsers(newUsers)
    })

    const newTickets: Record<string, Ticket> = {}
    Promise.all(
      payouts.map(p => {
        if (p.ticketId) {
          return collections
            .tickets(p.campaignId)
            .doc(p.ticketId)
            .get()
            .then(snap => {
              const ticket = snap.data()
              if (ticket) {
                newTickets[ticket.id] = ticket
              }
            })
        } else {
          return Promise.resolve()
        }
      })
    ).then(() => {
      setTickets(newTickets)
    })
  }, [payouts])

  return (
    <div>
      <div className='grid items-center grid-cols-12'>
        <div className='text-2xl col-span-6 md:col-span-9'>Payouts</div>
        <select
          className='text-input p-2 col-span-6 md:col-span-3'
          onChange={e => {
            setFilter(e.target.value as Status)
          }}
        >
          {allFilters.map(filter => {
            return (
              <option key={filter} value={filter}>
                {startCase(filter)}
              </option>
            )
          })}
        </select>
      </div>
      <div className='bg-white mt-4 rounded shadow'>
        <div className='grid grid-cols-12 gap-3 items-center p-2'>
          <div className='col-span-4 md:col-span-2 text-gray-500 text-xs font-bold'>
            NAME
          </div>
          <div className='col-span-4 md:col-span-2 text-gray-500 text-xs font-bold'>
            CAMPAIGN
          </div>
          <div className='col-span-4 md:col-span-2 text-gray-500 text-xs font-bold'>
            AMOUNT
          </div>
          <div className='col-span-4 md:col-span-2 text-gray-500 text-xs font-bold'>
            DESCRIPTION
          </div>
          <div className='col-span-4 md:col-span-2 text-gray-500 text-xs font-bold'>
            STATUS
          </div>
        </div>
        {payouts.map((payout, index) => {
          return (
            <Item
              payout={payout}
              index={index}
              user={users[payout.uid]}
              campaign={campaigns[payout.campaignId]}
              ticket={
                payout.ticketId ? tickets[payout.ticketId] : undefined
              }
            />
          )
        })}
      </div>
    </div>
  )
}
