import { Helmet } from 'react-helmet'
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation
} from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import viewerAtom from '../../atoms/viewerAtom'
import createTitle from '../../utils/createTitle'
import AdminPage from '../AdminPage/AdminPage'
import CreateCampaignPage from '../CreateCampaignPage/CreateCampaignPage'
import MyCharityPage from '../MyCharityPage/MyCharityPage'
import image from '../logo-green.png'
import Bell from './Bell'
import Dashboard from './Dashboard'
import FavoriteCharities from './FavoriteCharities'
import MyRaffles from './MyRaffles'
import Notifications from './Notifications'
import PortalCampaignPage from './PortalCampaignPage'
import RaffleBook from './RaffleBook'
import RaffleCreatedPage from './RaffleCreatedPage'
import Settings from './Settings'
import favoriteIcon from './ic-favorited.svg'
import homeIcon from './ic-home.svg'
import profileIcon from './ic-profile.svg'
import bookIcon from './ic-raffle-book.svg'
import rafflesIcon from './ic-raffles.svg'
import settingsIcon from './ic-settings.svg'

const MenuItem = ({
  image,
  title,
  path
}: {
  image: string
  title: string
  path: string
}) => {
  const location = useLocation()
  const content = (
    <Link to={path} className='Link'>
      <div className='px-5 py-4 flex items-center hover:bg-gray-200'>
        <img src={image} alt={title} className='h-4 w-4' />
        <div className='hidden md:inline-flex'>
          <div className='mx-3 text-sm text-colored'>{title}</div>
        </div>
      </div>
    </Link>
  )

  if (location.pathname.includes(path)) {
    return <div className='bg-gray-100'>{content}</div>
  }

  return content
}

export default () => {
  const user = useRecoilValue(userAtom)
  const viewer = useRecoilValue(viewerAtom)

  if (viewer === null) {
    return <Redirect to='/sign-in' />
  }

  return (
    <div>
      <Helmet>
        <title>{createTitle('Portal')}</title>
      </Helmet>
      <div className='bg-white'>
        <div className='py-3 container flex items-center justify-between'>
          <Link to='/' className='Link'>
            <img src={image} alt='Fundraise Club' className='h-10 mr-3' />
          </Link>
          <div className='flex items-center'>
            <Link to='/' className='Link'>
              <button className='button button-yellow px-5'>
                Back to Site
              </button>
            </Link>
            <Link to='/portal/notifications'>
              <Bell />
            </Link>
          </div>
        </div>
      </div>
      <div className='flex'>
        <div>
          <div className='bg-white shadow py-3'>
            {/* {user?.charity && (
              <div className='hidden md:block'>
                <div className='p-3'>
                  <div className='text-sm p-3 rounded-full shadow flex items-center'>
                    Charity Account
                  </div>
                </div>
                <div className='h-px bg-gray-100 w-full'></div>
              </div>
            )} */}
            <MenuItem
              title='Dashboard'
              image={homeIcon}
              path='/portal/dashboard'
            />
            <MenuItem
              title='Campaign Book'
              image={bookIcon}
              path='/portal/book'
            />
            {user?.charityState === 'approved' && (
              <MenuItem
                title='My Campaigns'
                image={rafflesIcon}
                path='/portal/my-raffles'
              />
            )}
            {user?.charityState === 'approved' && (
              <MenuItem
                title='Profile'
                image={profileIcon}
                path='/portal/my-charity'
              />
            )}
            <MenuItem
              title='Charities Following'
              image={favoriteIcon}
              path='/portal/favorite-charities'
            />
            <MenuItem
              title='Settings'
              image={settingsIcon}
              path='/portal/settings'
            />
            {user?.role === 'admin' && (
              <MenuItem
                title='Admin'
                image={settingsIcon}
                path='/portal/admin'
              />
            )}
            {user?.charityState !== 'approved' && (
              <Link className='Link' to='/portal/my-charity'>
                <div className='justify-center mt-3 hidden md:flex'>
                  <button className='button button-outline'>
                    Sign Up As Charity
                  </button>
                </div>
              </Link>
            )}
          </div>
        </div>
        <div className='flex-1'>
          <Switch>
            <Route
              exact
              path='/portal/my-raffles/:id'
              component={PortalCampaignPage}
            />
            <Route path='/portal/book' component={RaffleBook} />
            <Route>
              <div className='container py-3'>
                <Route path='/portal/dashboard' component={Dashboard} />
                <Route
                  path='/portal/notifications'
                  component={Notifications}
                />
                <Route
                  exact
                  path='/portal/my-raffles'
                  component={MyRaffles}
                />

                <Route
                  exact
                  path='/portal/create-raffle'
                  component={CreateCampaignPage}
                />
                <Route
                  exact
                  path='/portal/raffle-created/:id'
                  component={RaffleCreatedPage}
                />
                <Route
                  path='/portal/favorite-charities'
                  component={FavoriteCharities}
                />
                <Route
                  path='/portal/my-charity'
                  component={MyCharityPage}
                />
                <Route path='/portal/settings' component={Settings} />
                {user?.role === 'admin' && (
                  <Route path='/portal/admin' component={AdminPage} />
                )}
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}
