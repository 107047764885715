import { mdiLock } from '@mdi/js'
import Icon from '@mdi/react'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import { useSetCart } from '../../atoms/cartAtom'
import userAtom from '../../atoms/userAtom'
import useCardEndingIn from '../../hooks/useCardEndingIn'
import useCartInfo from '../../hooks/useCartInfo'
import Campaign from '../../types/Campaign'
import { achConfig } from '../../utils/ach'
import { functions } from '../../utils/firebase'
import AuthScreen from '../Auth/AuthScreen'
import NewCardForm from '../NewCardForm'
import Toggle from '../Toggle'
import icCreditCards from './ic-credit-cards.svg'

const ALLOW_CREDIT_CARD = false

export default () => {
  const user = useRecoilValue(userAtom)
  const [anon, setAnon] = useState<boolean>()
  const endingIn = useCardEndingIn()
  const [formOpen, setFormOpen] = useState(false)
  const { cart, campaigns, cartTotal } = useCartInfo()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const setCart = useSetCart()
  const paymentToken = useRef('pay_tok_SPECIMEN-' + Math.random()).current

  useEffect(() => {
    if (user && anon === undefined && user.anonymous !== undefined) {
      setAnon(user.anonymous)
    }
  }, [user, anon, setAnon])

  if (!user) {
    return <AuthScreen />
  }

  return (
    <div className='my-4 container'>
      <div className='text-colored text-4xl font-bold'>Checkout</div>
      <div className='grid mt-5 grid-cols-12 gap-5'>
        <div className='col-span-12 md:col-span-6'>
          <div className='flex justify-between items-center'>
            <div className='text-xl'>Payment Method</div>
            <div className='flex items-center'>
              <Icon path={mdiLock} color='#aaa' size={0.5} />
              <div className='text-sm ml-1'>Secure Payment</div>
            </div>
          </div>
          {ALLOW_CREDIT_CARD && (
            <div className='mt-3 bg-white p-4 rounded shadow'>
              <div className='flex justify-between items-center'>
                <div className='text-xl'>Credit Card</div>
                <img
                  src={icCreditCards}
                  alt='Credit Cards'
                  className='h-6'
                />
              </div>
              {endingIn && (
                <div className='mt-4'>
                  <div className='text-xs text-gray-500'>
                    Card number ending in
                  </div>
                  <div className='text-input mt-1'>{endingIn}</div>
                </div>
              )}
              <div className='mt-2'>
                {formOpen ? (
                  <NewCardForm dismiss={() => setFormOpen(false)} />
                ) : (
                  <button
                    className='button button-outline'
                    onClick={() => setFormOpen(true)}
                  >
                    Add New Card
                  </button>
                )}
              </div>
            </div>
          )}
          <div className='mt-3 bg-white p-4 rounded shadow'>
            <div className='flex justify-between items-center'>
              <div className='text-xl'>ACH</div>
            </div>
            <div className='mt-2 wrapper-pay-buttons'>
              <button
                className='button button-outline mr-2'
                onClick={() => {
                  var objRequestIframe = {
                    publicKey: achConfig.publishableKey,
                    sandbox: achConfig.sandbox,
                    saveBankDetails: false,
                    displayMethod: 'iframe',
                    paymentToken,
                    widgetContainerSelector: 'wrapper-pay-buttons',
                    style: {
                      buttonColor: '#00b660',
                      buttonLabelColor: '#ffffff',
                      buttonLabel: 'Pay with ACH'
                    },
                    lightBox: {
                      title: 'Checkout',
                      subtitle: 'Purchase spots',
                      logoUrl:
                        'https://fundraise.club/static/media/logo-footer.6ee0ea43.png',
                      formButtonLabel: 'PAY',
                      show_cart_summary: false
                    },
                    checkout: {
                      totalValue: cartTotal,
                      currency: 'USD',
                      description: cart.length + ' Items',
                      items: [
                        { title: 'Campaign Spots', price: cartTotal }
                      ],
                      customerEmail: user.email,
                      customerFirstName: user.firstName,
                      customerLastName: user.lastName
                    },
                    onError: async error => {
                      window.alert(error.message)
                    },
                    onSuccess: async function () {
                      console.log('onSuccess')

                      if (cart.length === 0) {
                        return
                      }

                      console.log(cart)

                      setLoading(true)
                      try {
                        const checkout =
                          functions.httpsCallable('checkout')
                        await checkout({
                          cart,
                          achPaymentToken: paymentToken
                        })
                        localStorage.setItem(
                          'last-checkout',
                          JSON.stringify(cart)
                        )
                        setCart([])
                        history.push('/checkout-complete')
                      } catch (e: any) {
                        alert(e.message)
                      } finally {
                        setLoading(false)
                      }
                    }
                  }

                  // @ts-ignore
                  var paynoteIframe = new PAYNOTE(objRequestIframe)
                  paynoteIframe.render()
                }}
              >
                Start ACH Checkout
              </button>
            </div>
          </div>
          <div className='flex justify-between items-center mt-3'>
            <Toggle
              text='Make my purchase anonymous'
              checked={!!anon}
              onClick={() => {
                setAnon(!anon)
              }}
            />
            <button
              className='button button-yellow'
              disabled={!endingIn || loading}
              onClick={async () => {
                if (cart.length === 0) {
                  return
                }

                console.log(cart)

                setLoading(true)
                try {
                  const checkout = functions.httpsCallable('checkout')
                  await checkout({ cart })
                  localStorage.setItem(
                    'last-checkout',
                    JSON.stringify(cart)
                  )
                  setCart([])
                  history.push('/checkout-complete')
                } catch (e: any) {
                  alert(e.message)
                } finally {
                  setLoading(false)
                }
              }}
            >
              {loading ? 'Please wait...' : 'Complete Purchase'}
            </button>
          </div>
        </div>
        <div className='col-span-12 md:col-span-6'>
          <div className='flex justify-between items-center'>
            <div className='text-xl'>Order Summary</div>
            <div className='text-xl font-bold'>${cartTotal}</div>
          </div>
          {cart.map(item => {
            const campaign = campaigns[item.campaignId] as
              | Campaign
              | undefined
            return (
              <div className='mt-3 bg-white rounded shadow p-3'>
                <div className='font-bold'>{campaign?.title}</div>
                <div className='mt-3 flex justify-between items-center'>
                  <div className='flex items-center'>
                    Purchasing:
                    <div className='text-primary ml-2 font-bold'>
                      #{item.number}
                    </div>
                  </div>
                  <div className='text-lg'>
                    ${item.shares.length * (campaign?.pricePerShare || 0)}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
