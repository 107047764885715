export default ({
  tabs,
  activeIndex,
  setIndex
}: {
  tabs: string[]
  activeIndex: number
  setIndex: (index: number) => void
}) => {
  return (
    <div className='mt-5 grid gap-3 grid-cols-12'>
      {tabs.map((tab, index) => {
        return (
          <div
            key={index}
            className='hover:bg-gray-400 col-span-6 md:col-span-3 lg:col-span-2 flex flex-col items-center cursor-pointer pt-2'
            onClick={() => {
              setIndex(index)
            }}
          >
            <div
              className={`text-sm ${
                index === activeIndex ? 'font-bold' : ''
              }`}
            >
              {tab}
            </div>
            <div
              className={`h-1 mt-1 w-28 ${
                index === activeIndex ? 'bg-primary' : ''
              }`}
            ></div>
          </div>
        )
      })}
    </div>
  )
}
