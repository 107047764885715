import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { CartItem } from '../types/User'

const key = 'cart'
const json = localStorage.getItem(key)

const cartAtom = atom<CartItem[]>({
  key: 'cartAtom',
  default: json ? JSON.parse(json) : []
})

export const useCart = () => useRecoilValue(cartAtom)
export const useSetCart = () => {
  const setCart = useSetRecoilState(cartAtom)

  return (items: CartItem[]) => {
    localStorage.setItem(key, JSON.stringify(items))
    setCart(items)
  }
}
