import { Link } from 'react-router-dom'
import Toolbar from '../Toolbar'
import banner from './temp_hero.webp'

export default () => {
  return (
    <div
      className='flex flex-col'
      style={{ backgroundImage: `url(${banner})` }}
    >
      <Toolbar light />
      <div className='h-80 md:h-96 flex flex-col items-center justify-center'>
        <div className='text-6xl text-white text-center'>
          <strong>LET'S RAISE</strong> TOGETHER
        </div>
        <div className='text-lg text-primary'>WHERE CHANCES ARE REAL</div>
        <Link to='/campaigns?t=live'>
          <button className='button button-yellow mt-4 px-12 text-lg'>
            Get Started!
          </button>
        </Link>
      </div>
    </div>
  )
}
