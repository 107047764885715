import clsx from 'clsx'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Campaign from '../../types/Campaign'

export default ({ campaign }: { campaign: Campaign }) => {
  const started = campaign.startsAt.toDate() < new Date()
  const ended = campaign.endsAt.toDate() < new Date()
  const startsInDays = moment(campaign.startsAt.toDate()).diff(
    moment(),
    'day'
  )
  const endsInDays = moment(campaign.endsAt.toDate()).diff(moment(), 'day')

  return (
    <div className='col-span-2 md:col-span-1 rounded shadow bg-white flex overflow-hidden items-center'>
      <div
        className={clsx(
          'h-20 w-2',
          campaign.canceled
            ? 'bg-red-500'
            : started && !ended && 'bg-primary'
        )}
      ></div>
      <Link
        to={`/portal/my-raffles/${campaign.id}`}
        className='Link flex-1 hover:bg-gray-100 p-3'
      >
        <div className='flex flex-1'>
          <img
            src={campaign.imageUrl}
            alt={campaign.title}
            className='h-14 w-14 rounded object-cover'
          />
          <div className='flex-1 ml-3'>
            <div className='font-bold'>{campaign.title}</div>
            <div className='mt-1 text-sm text-gray-500'>
              {campaign.canceled
                ? 'Canceled'
                : campaign.finishedAt
                ? 'Finished'
                : startsInDays > 0
                ? `Starting in ${startsInDays} ${
                    startsInDays === 1 ? 'day' : 'days'
                  }`
                : endsInDays > 0
                ? `Ending in ${endsInDays} ${
                    endsInDays === 1 ? 'day' : 'days'
                  }`
                : started
                ? 'Ending soon!'
                : 'Starting soon!'}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
