import moment from 'moment'
import Campaign from '../../types/Campaign'
import formatMoney from '../../utils/formatMoney'

export default ({ campaign }: { campaign: Campaign }) => {
  return (
    <div className='p-4 rounded border border-gray-200 bg-white'>
      <div className='text-xl font-bold '>About the fundraiser</div>
      <div className='font-bold muted text-xs mt-5'>DURATION</div>
      <div>
        Starts: {moment(campaign.startsAt.toMillis()).format('lll')}
      </div>
      <div>Ends: {moment(campaign.endsAt.toMillis()).format('lll')}</div>
      <div className='font-bold muted text-xs mt-5'>COST AND DETAILS</div>
      <div>Shares per Spot: {campaign.sharesPerSpot}</div>
      <div>Share Cost: {formatMoney(campaign.pricePerShare)}</div>
      <div>Spots Available: {campaign.spotsAvailable}</div>
      <div>Total Spots: {campaign.spots}</div>
      <div className='mt-2'></div>{' '}
      <div className='font-bold muted text-xs mt-5'>
        DELIVERY/COLLECTION
      </div>
      <div>{campaign.deliveryDescription}</div>
      <div className='font-bold muted text-xs mt-5'>PRIZES</div>
      {campaign.prizes.map((prize, index) => {
        return (
          <div className='mt-2' key={index}>
            <div className='font-bold text-2xl text-primary'>
              {formatMoney(prize.amount)}
            </div>
            <div className='text-sm'>{prize.description}</div>
          </div>
        )
      })}
    </div>
  )
}
