import 'firebase/firestore'
import { useEffect } from 'react'
import Modal from 'react-modal'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useRecoilState, useSetRecoilState } from 'recoil'
import platformState, { platformDoc } from './atoms/platformState'
import userAtom from './atoms/userAtom'
import viewerAtom from './atoms/viewerAtom'
import AboutPage from './components/AboutPage/AboutPage'
import AuthScreen from './components/Auth/AuthScreen'
import CampaignPage from './components/CampaignPage/CampaignPage'
import CampaignsPage from './components/CampaignsPage'
import CartPage from './components/CartPage/CartPage'
import CharityPage from './components/CharityPage'
import CheckoutCompletePage from './components/CheckoutCompletePage'
import CheckoutPage from './components/CheckoutPage/CheckoutPage'
import ContactUsPage from './components/ContactUsPage/ContactUsPage'
import CreateCampaignPage from './components/CreateCampaignPage/CreateCampaignPage'
import DeleteAccountPage from './components/DeleteAccountPage'
import FaqPage from './components/FaqPage/FaqPage'
import Footer from './components/Footer'
import ForgotPassword from './components/ForgotPassword'
import ForgotPasswordSent from './components/ForgotPasswordSent/ForgotPasswordSent'
import HomePage from './components/HomePage/HomePage'
import MyCharityPage from './components/MyCharityPage/MyCharityPage'
import Portal from './components/Portal/Portal'
import TermsPage from './components/TermsPage'
import Toolbar from './components/Toolbar'
import Platform from './types/Platform'
import { auth, collections } from './utils/firebase'

Modal.setAppElement('#root')

function App() {
  const setPlatform = useSetRecoilState(platformState)
  const [viewer, setViewer] = useRecoilState(viewerAtom)
  const setUser = useSetRecoilState(userAtom)

  useEffect(() => {
    platformDoc.onSnapshot(snap => {
      setPlatform(snap.data() as Platform)
    })
  }, [setPlatform])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const ref = params.get('ref')
    if (ref) {
      ref.split(',').forEach(id => [localStorage.setItem(id, 'true')])
    }
  }, [])

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setViewer(user ? { uid: user.uid, email: user.email } : null)
    })
  }, [setViewer])

  useEffect(() => {
    if (!viewer) {
      setUser(undefined)
    } else {
      const unsub = collections.users.doc(viewer.uid).onSnapshot(snap => {
        if (snap.exists) {
          setUser(snap.data())
        }
      })
      return () => unsub()
    }
  }, [viewer, setUser])

  return (
    <BrowserRouter>
      <div className='content'>
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route path='/portal' component={Portal} />
          <Route>
            <Toolbar />
            <Switch>
              <Route exact path='/contact-us' component={ContactUsPage} />
              <Route exact path='/about' component={AboutPage} />
              <Route exact path='/faqs' component={FaqPage} />
              <Route exact path='/campaigns' component={CampaignsPage} />
              <Route exact path='/terms' component={TermsPage} />
              <Route
                exact
                path='/campaigns/create'
                component={CreateCampaignPage}
              />
              <Route
                exact
                path='/campaigns/:id/edit'
                component={CreateCampaignPage}
              />
              <Route
                exact
                path='/campaigns/:id/:number'
                component={CampaignPage}
              />
              <Route
                exact
                path='/campaigns/:id'
                component={CampaignPage}
              />
              <Route
                exact
                path='/account/charity'
                component={MyCharityPage}
              />
              <Route exact path='/charities/:id' component={CharityPage} />
              <Route exact path='/sign-up' component={AuthScreen} />
              <Route exact path='/sign-in' component={AuthScreen} />
              <Route
                exact
                path='/forgot-password'
                component={ForgotPassword}
              />
              <Route
                exact
                path='/forgot-password-sent'
                component={ForgotPasswordSent}
              />
              <Route exact path='/cart' component={CartPage} />
              <Route exact path='/checkout' component={CheckoutPage} />
              <Route
                exact
                path='/checkout-complete'
                component={CheckoutCompletePage}
              />
              <Route
                exact
                path='/delete-account'
                component={DeleteAccountPage}
              />
            </Switch>
          </Route>
        </Switch>
      </div>
      <Footer />
    </BrowserRouter>
  )
}

export default App
