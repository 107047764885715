export default {
  primary: '#4ab274',
  primaryLightBackground: '#eff8f1',
  coloredText: '#013961',
  secondary: '#fcad31',
  darkBackground: '#00375f',
  board: {
    open: '#4ab274',
    purchased: '#00375f',
    partial: '#74cadb',
    reserved: '#cfd399'
  }
}
