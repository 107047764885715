import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import createTitle from '../../utils/createTitle'
import { auth, collections } from '../../utils/firebase'
import TextInput from '../TextInput'

export default () => {
  const user = useRecoilValue(userAtom)
  const history = useHistory()
  const [saving, setSaving] = useState(false)

  const fnRef = useRef<any>()
  const lnRef = useRef<any>()
  const emailRef = useRef<any>()
  const phoneRef = useRef<any>()
  const addressRef = useRef<any>()
  const cityRef = useRef<any>()
  const stateRef = useRef<any>()
  const countryRef = useRef<any>()
  const paypalRef = useRef<any>()

  if (!user) {
    return null
  }

  return (
    <div>
      <Helmet>
        <title>{createTitle('Personal Information')}</title>
      </Helmet>
      <div className='text-2xl'>Personal Information</div>
      <div className='mt-4 bg-white p-4 rounded shadow'>
        <div className='grid grid-cols-2 gap-4'>
          <div className='col-span-2 md:col-span-1'>
            <TextInput
              defaultValue={user.firstName}
              label='First Name'
              inputRef={fnRef}
              required
            />
          </div>
          <div className='col-span-2 md:col-span-1'>
            <TextInput
              defaultValue={user.lastName}
              label='Last Name'
              inputRef={lnRef}
              required
            />
          </div>
        </div>
        <div className='mt-2'>
          <TextInput
            defaultValue={user.email}
            label='Email'
            inputRef={emailRef}
            type='email'
          />
        </div>
        <div className='mt-2'>
          <TextInput
            defaultValue={user.phone}
            label='Phone Number'
            inputRef={phoneRef}
          />
        </div>
        <div className='mt-2'>
          <TextInput
            label='Address'
            defaultValue={user.address}
            inputRef={addressRef}
          />
        </div>
        <div className='mt-2'>
          <TextInput
            defaultValue={user.city}
            label='City'
            inputRef={cityRef}
          />
        </div>
        <div className='grid grid-cols-2 gap-4 mt-2'>
          <div className='col-span-2 md:col-span-1'>
            <TextInput
              defaultValue={user.state}
              label='State'
              inputRef={stateRef}
            />
          </div>
          <div className='col-span-2 md:col-span-1'>
            <TextInput
              defaultValue={user.country}
              label='Country'
              inputRef={countryRef}
            />
          </div>
        </div>
        <div className='mt-2'>
          <TextInput
            defaultValue={user.paypalEmail}
            label='PayPal Email (for payouts)'
            inputRef={paypalRef}
          />
        </div>
      </div>
      <div className='flex justify-between items-center mt-3'>
        <Link to='/portal/settings' className='Link'>
          <button className='button button-outline'>Cancel</button>
        </Link>
        <button
          disabled={saving}
          className='button button-yellow'
          onClick={async () => {
            const user = auth.currentUser
            if (!user) {
              return
            }

            setSaving(true)
            try {
              await collections.users.doc(user.uid).update({
                firstName: fnRef.current.value,
                lastName: lnRef.current.value,
                email: emailRef.current.value,
                phone: phoneRef.current.value,
                address: addressRef.current.value,
                city: cityRef.current.value,
                state: stateRef.current.value,
                country: countryRef.current.value,
                paypalEmail: paypalRef.current.value
              })
              await user.updateProfile({
                displayName: `${fnRef.current.value} ${lnRef.current.value}`
              })
              history.goBack()
            } catch (e: any) {
              alert(e.message)
            }
          }}
        >
          {saving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </div>
  )
}
