const height = 12
const radius = height / 2
const circleSize = height * 1.5

export default ({ max, current }: { max: number; current: number }) => {
  const percent = current / max
  return (
    <div className='flex items-center'>
      <div
        className='bg-primary'
        style={{
          flex: percent,
          height,
          borderTopLeftRadius: radius,
          borderBottomLeftRadius: radius
        }}
      />
      <div style={{ position: 'relative' }}>
        <div
          className='bg-primary'
          style={{
            position: 'absolute',
            height: circleSize,
            width: circleSize,
            borderRadius: circleSize / 2,
            top: circleSize * -0.5,
            left: circleSize * -0.5
          }}
        />
      </div>
      <div
        className='bg-gray-200'
        style={{
          flex: 1 - percent,
          height,
          borderTopRightRadius: radius,
          borderBottomRightRadius: radius
        }}
      />
    </div>
  )
}
