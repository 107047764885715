import algoliasearch from 'algoliasearch'

const client = algoliasearch(
  '3PVHZD8MEN',
  'c918199ba6817154403b764745c8507b'
)

export default {
  campaigns: client.initIndex('campaigns')
}
