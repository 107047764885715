import image from './ic-password-reset-sent.svg'

export default () => {
  return (
    <div className='container my-5 flex-col flex items-center'>
      <div className='text-colored font-bold text-3xl'>
        Your password reset email has been sent
      </div>
      <div className='mt-3 text-sm'>
        Check your email for a password recovery link.
      </div>
      <img
        src={image}
        alt='Password Reset Sent'
        className='object-contain w-20 h-20 mt-5'
      />
    </div>
  )
}
