import { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { Helmet } from 'react-helmet'
import createTitle from '../../utils/createTitle'
import { triggerEmail } from '../../utils/triggerEmail'
import TextInput from '../TextInput'
import hero from '../img-contact.jpg'

const emails = ['yitzchokschwartz@gmail.com', 'mosheyneuman@gmail.com']

export default () => {
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  return (
    <div className='grid grid-cols-12 gap-4'>
      <Helmet>
        <title>{createTitle('Contact Us')}</title>
      </Helmet>
      <div className='hidden lg:inline-grid lg:col-span-5'>
        <img
          className='h-full w-full object-cover'
          src={hero}
          alt='Women laughing'
        />
      </div>
      <div className='col-span-12 lg:col-span-7'>
        <div className='container'>
          <h2 className='text-2xl font-bold'>Contact Us</h2>
          <form
            onSubmit={async e => {
              e.preventDefault()
              if (!name) return alert('Please enter your name!')
              if (!email) return alert('Please enter your email!')
              if (!message) return alert('Please enter a message!')

              setLoading(true)
              try {
                await triggerEmail({
                  to: emails,
                  message: {
                    subject: 'Fundraise Club Message - Contact Us',
                    text: `A user has contacted Fundraise Club via the Contact Us form.\n\nName: ${name}\nEmail: ${email}\nPhone: ${phone}\nCompany: ${company}\n\nMessage: ${message}`
                  }
                })
                setName('')
                setEmail('')
                setPhone('')
                setCompany('')
                setMessage('')
                alert(
                  'Your message was sent successfully. Please allow us up to 48 hours to reply.'
                )
              } catch (e) {
                console.error(e)
              } finally {
                await setLoading(false)
              }
            }}
          >
            <Row>
              <Col xs={12} md={6} className='mt-3'>
                <div className='mb-1'>Email</div>
                <div className='text-sm'>info@fundraise.club</div>
              </Col>
              <Col xs={12} md={6} className='mt-3'>
                <div className='mb-1'>Address</div>
                <div className='text-sm'>
                  28 Valley Road. Montclair, NJ 07042
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className='mt-3'>
                <div className='mb-1'>Phone Number</div>
                <div className='text-sm'>+1 (201) 357-0707</div>
              </Col>
              <Col xs={12} md={6} className='mt-3'></Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className='mt-3'>
                <TextInput
                  label='Name'
                  onTextChange={setName}
                  value={name}
                />
              </Col>
              <Col xs={12} md={6} className='mt-3'>
                <TextInput
                  label='Company'
                  onTextChange={setCompany}
                  value={company}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6} className='mt-3'>
                <TextInput
                  label='Phone Number'
                  onTextChange={setPhone}
                  value={phone}
                />
              </Col>
              <Col xs={12} md={6} className='mt-3'>
                <TextInput
                  type='email'
                  label='Email'
                  onTextChange={setEmail}
                  value={email}
                />
              </Col>
            </Row>
            <div className='mt-3 mb-1 text-xs text-gray-500'>Message</div>
            <textarea
              className='text-input'
              rows={3}
              onChange={e => {
                setMessage(e.target.value)
              }}
              value={message}
            />
            <button
              disabled={loading}
              className='button button-yellow mt-3 mb-5'
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
