import { mdiHeartOutline } from '@mdi/js'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import Campaign from '../../types/Campaign'
import Charity from '../../types/Charity'
import createTitle from '../../utils/createTitle'
import { collections } from '../../utils/firebase'
import getActiveCampaigns from '../../utils/getActiveCampaigns'
import PortalTabTitle from './PortalTabTitle'

const CharityItem = ({ id }: { id: string }) => {
  const [charity, setCharity] = useState<Charity>()
  const [campaigns, setCampaigns] = useState<Campaign[]>()

  useEffect(() => {
    collections.users
      .doc(id)
      .get()
      .then(snap => {
        setCharity(snap.data()?.charity)
      })

    getActiveCampaigns(id, 10).then(setCampaigns)
  }, [id])

  return (
    <Link to={`/charities/${id}`} className='Link'>
      <div className='p-4 bg-white grid grid-cols-12 gap-2 items-center shadow rounded hover:bg-gray-100'>
        <div className='flex flex-1 items-center col-span-12 md:col-span-9'>
          <img
            src={charity?.imageUrl}
            alt={charity?.name || 'Loading'}
            className='h-10 w-10 object-cover rounded'
          />
          <div className='ml-3'>{charity?.name || 'Loading'}</div>
        </div>

        {campaigns && (
          <button className='button button-green col-span-12 md:col-span-3'>
            {campaigns.length >= 10 ? '10+' : campaigns.length} Active{' '}
            {campaigns.length === 1 ? 'Campaign' : 'Campaigns'}
          </button>
        )}
      </div>
    </Link>
  )
}

export default () => {
  const user = useRecoilValue(userAtom)

  const charityIds = useMemo(() => {
    return user?.favoriteCharityIds || []
  }, [user])

  return (
    <div>
      <Helmet>
        <title>{createTitle('Charities Following')}</title>
      </Helmet>
      <PortalTabTitle icon={mdiHeartOutline} title='Charities Following' />
      {charityIds.map(id => {
        return <CharityItem key={id} id={id} />
      })}
    </div>
  )
}
