import { mdiFlagCheckered } from '@mdi/js'
import Icon from '@mdi/react'
import React, { useEffect, useState } from 'react'
import Campaign from '../../types/Campaign'
import campaignState from '../../utils/campaignState'
import getCountdown from '../../utils/getCountdown'

export default ({ campaign }: { campaign: Campaign }) => {
  const setR = useState(Math.random())[1]
  useEffect(() => {
    const id = setInterval(() => setR(Math.random()), 1000)
    return () => clearInterval(id)
  }, [setR])

  const { finished, started, live } = campaignState(campaign)
  const { days, hours, minutes, seconds } = getCountdown(campaign)

  return (
    <div
      className={
        campaign.canceled
          ? 'bg-red-500'
          : finished
          ? 'bg-primary'
          : 'bg-dark-blue'
      }
    >
      <div className='container py-2 flex justify-between items-center text-white'>
        <div className='flex items-center'>
          <Icon color='white' size={1} path={mdiFlagCheckered} />
          <div className='ml-2 text-sm'>
            {campaign.canceled
              ? 'Canceled'
              : finished
              ? 'Finished'
              : live
              ? 'Almost finished'
              : !started
              ? 'Starting soon'
              : ''}
          </div>
        </div>
        {!finished && campaign.endsAt.toMillis() > Date.now() && (
          <div className='flex items-center font-bold'>
            <div className='text-xs'>
              {started ? 'Time Remaining:' : 'Starts In:'}
            </div>
            <div className='ml-3 text-sm'>{days} Days</div>
            <div className='ml-2 text-sm'>{hours} Hrs</div>
            <div className='ml-2 text-sm'>{minutes} Mins</div>
            <div className='ml-2 text-sm'>{seconds} Sec</div>
          </div>
        )}
      </div>
    </div>
  )
}
