import clsx from 'clsx'
import { isMoment } from 'moment'
import { useEffect, useState } from 'react'
import Datetime from 'react-datetime'
import { Helmet } from 'react-helmet'
import { useHistory, useParams } from 'react-router'
import useCategories from '../../hooks/useCategories'
import usePlatform from '../../hooks/usePlatform'
import Campaign from '../../types/Campaign'
import createTitle from '../../utils/createTitle'
import {
  auth,
  collections,
  firestore,
  storage
} from '../../utils/firebase'
import GreenToggle from '../GreenToggle'
import PageLoading from '../PageLoading'
import TextInput from '../TextInput'
import toggleOff from '../ic-toggle-off.svg'
import toggleOn from '../ic-toggle-on.svg'

const Step = ({
  step,
  title,
  complete
}: {
  step: number
  title: string
  complete: boolean
}) => {
  return (
    <div className='flex-1'>
      <div className='text-sm font-bold'>STEP {step}</div>
      <div className='text-primary'>{title}</div>
      <div
        className={clsx(
          'w-full',
          'h-2',
          'mt-1',
          complete ? 'bg-primary' : 'bg-gray-300'
        )}
      />
    </div>
  )
}

export default () => {
  const { id } = useParams<{ id?: string }>()
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const categories = useCategories()
  const [step, setStep] = useState(1)
  const [file, setFile] = useState<any>()
  const [prizeOpen, setPrizeOpen] = useState(!id)
  const { commission } = usePlatform()
  const [state, setState] = useState<
    Omit<
      Campaign,
      | 'endsAt'
      | 'startsAt'
      | 'raised'
      | 'participants'
      | 'id'
      | 'spotsAvailable'
      | 'pricePerShare'
      | 'createdAt'
    > & {
      startsAt?: Date
      endsAt?: Date
      id: undefined
      pricePerTicket: number
    }
  >({
    categoryIds: [],
    deliveryDescription: '',
    description: '',
    uid: '',
    imageUrl: '',
    title: '',
    id: undefined,
    pricePerTicket: 0,
    spots: 50,
    sharesPerSpot: 10,
    genre: '',
    minimumRaise: 0,
    prizes: [],
    finishedAt: null,
    commission
  })

  const [prizeTitle, setPrizeTitle] = useState('')
  const [prizeAmount, setPrizeAmount] = useState(0)
  const [prizeDescription, setPrizeDescription] = useState('')
  const started =
    !!id && (!state.startsAt ? false : state.startsAt < new Date())

  useEffect(() => {
    if (id) {
      setLoading(true)
      collections.campaigns
        .doc(id)
        .get()
        .then(snap => {
          const data = snap.data()
          if (!data) {
            return
          }

          setState({
            ...data,
            startsAt: data.startsAt.toDate(),
            endsAt: data.endsAt.toDate(),
            pricePerTicket: data.pricePerShare * data.sharesPerSpot,
            commission: data.commission,
            id: undefined
          })
        })
        .finally(() => setLoading(false))
    }
  }, [id])

  const title = !id ? 'Create a Campaign' : 'Edit Campaign'

  if (loading) {
    return <PageLoading />
  }

  const Toggle = ({ sharesPerSpot }: { sharesPerSpot: number }) => {
    return (
      <div
        className='flex items-center cursor-pointer p-1 mr-2'
        onClick={() => {
          if (started) {
            return
          }

          setState({
            ...state,
            sharesPerSpot
          })
        }}
      >
        <img
          className='w-8 h-8'
          src={
            state.sharesPerSpot === sharesPerSpot ? toggleOn : toggleOff
          }
          alt={
            state.sharesPerSpot === sharesPerSpot
              ? 'Toggle On'
              : 'Toggle Off'
          }
        />
        <label htmlFor='shares-20' className='ml-1 text-sm'>
          {(1 / sharesPerSpot) * 100}%
        </label>
      </div>
    )
  }

  const submit = async () => {
    const user = auth.currentUser

    if (!user) {
      return alert('Not logged in!')
    }

    setSaving(true)
    let imageUrl = state.imageUrl

    // TODO deal with resized images
    if (file) {
      const id = collections.users.doc().id
      const ref = await storage.ref('/images').child(id).child('original')
      await ref.put(file)
      imageUrl = await ref.getDownloadURL()
      console.log('uploaded image to', imageUrl)
    }

    if (!imageUrl) {
      setSaving(false)
      return alert('Please select an image.')
    }

    try {
      if (id) {
        const data: any = { ...state }
        delete data.id
        delete data.commission
        await collections.campaigns.doc(id).update({ ...data, imageUrl })
      } else {
        // remove id from spread
        const { id, pricePerTicket, ...rest } = state
        // @ts-ignore
        const campaign: Campaign = {
          ...rest,
          uid: user.uid,
          participants: 0,
          raised: 0,
          spotsAvailable: state.spots,
          imageUrl,
          pricePerShare: pricePerTicket / state.sharesPerSpot,
          commission
        }
        const ref = firestore.collection('campaigns').doc()

        await ref.set({
          ...campaign,
          createdAt: new Date(),
          finishedAt: false
        })
        history.push(`/portal/raffle-created/${ref.id}`)
      }
    } catch (e: any) {
      alert(e.message)
    } finally {
      setSaving(false)
    }
  }

  const displayImage = file ? URL.createObjectURL(file) : state.imageUrl
  const tab1 = (
    <div className='mt-4 p-4 bg-white rounded-sm shadow-sm'>
      {displayImage && (
        <img
          src={file ? URL.createObjectURL(file) : state.imageUrl}
          alt='Cover'
          className='object-cover h-48 w-full rounded'
        />
      )}
      <div className='mb-1 mt-3 text-xs text-gray-500'>Cover Image</div>
      <input
        accept='image/*'
        type='file'
        onChange={e => {
          const { files } = e.target
          if (files) {
            setFile(files[0])
          }
        }}
      />
      <TextInput
        className='mt-4'
        value={state.title}
        label='Title'
        onTextChange={title => {
          setState({
            ...state,
            title
          })
        }}
      />
      <div className='mb-1 mt-4 text-xs text-gray-500'>Categories</div>
      <div className='grid grid-cols-12 gap-3 mt-2'>
        {categories.map(({ name, id }) => {
          const checked = state.categoryIds.includes(id)
          return (
            <GreenToggle
              key={id}
              text={name}
              checked={checked}
              onClick={() => {
                setState({
                  ...state,
                  categoryIds: checked
                    ? state.categoryIds.filter(otherId => otherId !== id)
                    : [...state.categoryIds, id]
                })
              }}
            />
          )
        })}
      </div>
      <div className='mb-1 mt-4 text-xs text-gray-500'>
        Short Description
      </div>
      <textarea
        className='text-input'
        rows={2}
        placeholder='Add a short description for your campaign. Should be two lines max.'
        value={state.description}
        onChange={e => {
          setState({
            ...state,
            description: e.target.value
          })
        }}
      />
    </div>
  )

  const tab2 = (
    <div className='mt-4 p-4 bg-white rounded-sm shadow-sm'>
      <div className='text-lg'>Details</div>
      <div className='mt-3 grid grid-cols-2 gap-4'>
        <div className='col-span-1'>
          <div className='mb-1 text-xs text-gray-500'>Starts At</div>
          <div className='text-input'>
            <Datetime
              value={state.startsAt}
              onChange={input => {
                if (isMoment(input)) {
                  setState({
                    ...state,
                    startsAt: input.toDate()
                  })
                }
              }}
            />
          </div>
        </div>
        <div className='col-span-1'>
          <div className='mb-1 text-xs text-gray-500'>Ends At</div>
          <div className='text-input'>
            <Datetime
              value={state.endsAt}
              onChange={input => {
                if (isMoment(input)) {
                  setState({
                    ...state,
                    endsAt: input.toDate()
                  })
                }
              }}
            />
          </div>
        </div>
        <TextInput
          label='Total Tickets Available'
          type='number'
          value={state.spots}
          disabled={!!id}
          onTextChange={e => {
            setState({
              ...state,
              spots: parseInt(e)
            })
          }}
        />
        <TextInput
          className='col-span-1'
          label='Cost per Ticket'
          type='number'
          value={state.pricePerTicket}
          disabled={!!id}
          onTextChange={e => {
            setState({
              ...state,
              pricePerTicket: parseFloat(e)
            })
          }}
        />
        <TextInput
          className='col-span-1'
          label='Overall Raise Amount'
          value={'$' + state.pricePerTicket * state.spots}
          disabled
        />
        <TextInput
          className='col-span-1'
          label='Lowest Raise Amount'
          type='number'
          value={state.minimumRaise}
          onTextChange={e => {
            setState({
              ...state,
              minimumRaise: parseFloat(e)
            })
          }}
        />
        <div className='col-span-2 text-center text-sm text-gray-600'>
          * Gross ticket sales are subject to a {commission * 100}%
          commission for the Fundraise Club platform.
        </div>
      </div>
      <div className='font-bold mt-5'>Minimum Percentage Purchased</div>
      <div className='text-sm'>
        Allow spots to be split up into this percentage
      </div>
      <div className='flex mt-2'>
        <Toggle sharesPerSpot={20} />
        <Toggle sharesPerSpot={10} />
        <Toggle sharesPerSpot={5} />
        <Toggle sharesPerSpot={4} />
        <Toggle sharesPerSpot={2} />
        <Toggle sharesPerSpot={1} />
      </div>
    </div>
  )

  const tab3 = (
    <div className='mt-4'>
      <div className='flex justify-between items-center'>
        <div className='text-lg'>Prizes</div>
        {!prizeOpen && (
          <button
            className='button button-green'
            onClick={() => {
              setPrizeOpen(true)
            }}
          >
            Add Prize
          </button>
        )}
      </div>
      <div className='mt-3 rounded-sm bg-white p-4 shadow-sm'>
        <TextInput
          label='Delivery'
          value={state.deliveryDescription}
          placeholder='e.g. Prizes will be delivered via PayPal'
          onTextChange={deliveryDescription => {
            setState({
              ...state,
              deliveryDescription
            })
          }}
        />
      </div>
      <div className='mt-3'>
        {state.prizes.map((prize, index) => {
          return (
            <div
              className='rounded-sm bg-white shadow-sm p-4'
              key={index.toString()}
            >
              <div className='flex items-center '>
                <div className='flex-1 font-bold'>{prize.title}</div>
                <div className='text-lg'>${prize.amount}</div>
              </div>
              <div className='text-sm mt-2'>{prize.description}</div>
            </div>
          )
        })}
        {prizeOpen && (
          <div className='mt-3 rounded-sm bg-white shadow-sm p-4'>
            <TextInput
              value={prizeTitle}
              label='Prize Title'
              onTextChange={setPrizeTitle}
            />
            <TextInput
              className='mt-3'
              value={prizeAmount}
              label='Prize Amount'
              type='number'
              onTextChange={value => {
                setPrizeAmount(parseInt(value))
              }}
            />
            <div className='mt-3'>
              <div className='mb-1 text-sm text-gray-500'>Description</div>
              <textarea
                value={prizeDescription}
                className='w-full text-input'
                rows={2}
                onChange={e => {
                  setPrizeDescription(e.target.value)
                }}
              />
            </div>
            <div className='mt-3 flex justify-between items-center'>
              {state.prizes.length > 0 ? (
                <button
                  onClick={() => setPrizeOpen(false)}
                  className='button button-outline'
                >
                  Cancel
                </button>
              ) : (
                <div />
              )}
              <button
                className='button button-yellow'
                onClick={() => {
                  if (!prizeTitle) {
                    return alert('Please add a title.')
                  }

                  setState({
                    ...state,
                    prizes: [
                      ...state.prizes,
                      {
                        title: prizeTitle,
                        amount: prizeAmount,
                        description: prizeDescription
                      }
                    ]
                  })
                  setPrizeAmount(0)
                  setPrizeTitle('')
                  setPrizeDescription('')
                  setPrizeOpen(false)
                }}
              >
                Save Prize
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div>
      <Helmet>
        <title>{createTitle(title)}</title>
      </Helmet>
      <h1 className='mb-3 text-3xl font-bold'>{title}</h1>
      {!id && (
        <div className='mt-3 flex'>
          <Step step={1} title='Campaign Information' complete />
          <Step step={2} title='Campaign Details' complete={step >= 2} />
          <Step step={3} title='Prizes' complete={step >= 3} />
        </div>
      )}
      {id || step === 1 ? tab1 : null}
      {id || step === 2 ? tab2 : null}
      {id || step === 3 ? tab3 : null}
      <div className='mt-4 flex justify-between items-center'>
        {!id && step > 1 ? (
          <button
            className='button button-outline'
            onClick={() => {
              setStep(step - 1)
            }}
          >
            Previous Page
          </button>
        ) : (
          <div />
        )}
        {!id && step < 3 ? (
          <button
            className='button button-yellow'
            onClick={() => setStep(step + 1)}
          >
            Continue
          </button>
        ) : (
          <button
            className='button button-green px-5'
            disabled={
              loading ||
              saving ||
              !state.startsAt ||
              !state.endsAt ||
              state.title === '' ||
              state.description === '' ||
              state.deliveryDescription === '' ||
              state.spots === 0
            }
            type='submit'
            onClick={submit}
          >
            {saving
              ? 'Please wait...'
              : id
              ? 'Save Campaign'
              : 'Create Campaign'}
          </button>
        )}
      </div>

      {/* <div className='mb-1 mt-3 text-xs text-gray-500'>Categories</div>
      {categories.map(category => {
        return (
          <div>
            <input
              type='checkbox'
              key={category.id}
              id={category.id}
              checked={state.categoryIds.includes(category.id)}
              onChange={e => {
                setState({
                  ...state,
                  categoryIds: uniq(
                    e.target.checked
                      ? [...state.categoryIds, category.id]
                      : state.categoryIds.filter(
                          cId => cId !== category.id
                        )
                  )
                })
              }}
            />
            <label className='ml-2' htmlFor={category.id}>
              {category.name}
            </label>
          </div>
        )
      })} */}
    </div>
  )
}
