import { isUndefined, omitBy } from 'lodash'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import useCategories from '../../hooks/useCategories'
import User from '../../types/User'
import createTitle from '../../utils/createTitle'
import { auth, collections } from '../../utils/firebase'
import GreenToggle from '../GreenToggle'
import TextInput from '../TextInput'
import Toggle from '../Toggle'

export default () => {
  const user = useRecoilValue(userAtom)
  const categories = useCategories()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [input, setInput] = useState<
    Pick<
      User,
      | 'favoriteCategoryIds'
      | 'anonymous'
      | 'raffleRangeMin'
      | 'raffleRangeMax'
      | 'enabledNotifications'
    > & {
      preferredCampaignSpotsMax: string
      preferredPrizeAmount: string
    }
  >({ preferredCampaignSpotsMax: '', preferredPrizeAmount: '' })

  useEffect(() => {
    if (!user) {
      return
    }

    const {
      favoriteCategoryIds,
      anonymous,
      raffleRangeMin,
      raffleRangeMax,
      enabledNotifications,
      preferredCampaignSpotsMax,
      preferredPrizeAmount
    } = user

    setInput({
      favoriteCategoryIds,
      anonymous,
      raffleRangeMin,
      raffleRangeMax,
      enabledNotifications,
      preferredCampaignSpotsMax:
        preferredCampaignSpotsMax?.toString() || '',
      preferredPrizeAmount: preferredPrizeAmount?.toString() || ''
    })
  }, [user])

  const NotificationToggle = ({
    type,
    text
  }: {
    type: string
    text: string
  }) => {
    return (
      <Toggle
        checked={!!input.enabledNotifications?.[type]}
        text={text}
        onClick={() => {
          const value = !input.enabledNotifications?.[type]
          if (!input.enabledNotifications) {
            setInput({
              ...input,
              enabledNotifications: {
                [type]: value
              }
            })
          } else {
            setInput({
              ...input,
              enabledNotifications: {
                ...input.enabledNotifications,
                [type]: value
              }
            })
          }
        }}
      />
    )
  }

  return (
    <div>
      <Helmet>
        <title>{createTitle('Notification Preferences')}</title>
      </Helmet>
      <div className='text-2xl'>Notification Preferences</div>
      <div className='mt-4 rounded bg-white shadow p-4'>
        <div className='text-lg mb-2'>Preferences</div>
        <Toggle
          checked={!!input.anonymous}
          text='Set all my purchases as anonymous'
          onClick={() => {
            setInput({
              ...input,
              anonymous: !input.anonymous
            })
          }}
        />
        <div className='text-sm mt-3'>Favorite Categories</div>
        <div className='grid grid-cols-12 gap-3 mt-2'>
          {categories.map(({ name, id }) => {
            const checked = !!input.favoriteCategoryIds?.[id]
            return (
              <GreenToggle
                key={id}
                text={name}
                checked={checked}
                onClick={() => {
                  if (!input.favoriteCategoryIds) {
                    setInput({
                      ...input,
                      favoriteCategoryIds: {
                        [id]: true
                      }
                    })
                  } else {
                    setInput({
                      ...input,
                      favoriteCategoryIds: {
                        ...input.favoriteCategoryIds,
                        [id]: !checked
                      }
                    })
                  }
                }}
              />
            )
          })}
        </div>
        <div className='text-lg mt-3'>Campaign Types</div>
        <div className='text-xs'>
          Notify me when these types of raffles are created
        </div>
        <NotificationToggle
          type='prizes'
          text='Campaigns with prizes over this amount'
        />
        <TextInput
          type='number'
          value={input.preferredPrizeAmount}
          onTextChange={preferredPrizeAmount => {
            setInput({
              ...input,
              preferredPrizeAmount
            })
          }}
        />
        <div className='mt-3'></div>
        <NotificationToggle
          type='tickets'
          text='Campaigns under this number of spots'
        />
        <TextInput
          type='number'
          value={input.preferredCampaignSpotsMax}
          onTextChange={preferredCampaignSpotsMax => {
            setInput({
              ...input,
              preferredCampaignSpotsMax
            })
          }}
        />
        <div className='mt-3 text-lg'>Email Settings</div>
        <NotificationToggle
          type='charity'
          text='Notify me of a new raffle by a charity I follow'
        />
        <NotificationToggle
          type='category'
          text='Notify me of a new raffle in my preferred categories'
        />
        <NotificationToggle
          type='winner'
          text='Notify me when a raffle winner is selected'
        />
        <NotificationToggle
          type='promotion'
          text='Receive promotion emails based on my preferences'
        />
      </div>
      <div className='flex mt-3 justify-between items-center'>
        <Link to='/portal/settings' className='Link'>
          <button className='button button-outline'>Cancel</button>
        </Link>
        <button
          className='button button-yellow'
          disabled={loading}
          onClick={() => {
            const user = auth.currentUser
            if (!user) {
              return
            }

            const update: Partial<User> = {
              ...omitBy(input, isUndefined)
            }

            const preferredPrizeAmount = parseInt(
              input.preferredPrizeAmount,
              10
            )
            const preferredCampaignSpotsMax = parseInt(
              input.preferredCampaignSpotsMax,
              10
            )

            if (preferredPrizeAmount) {
              update.preferredPrizeAmount = preferredPrizeAmount
            }
            if (preferredCampaignSpotsMax) {
              update.preferredCampaignSpotsMax = preferredCampaignSpotsMax
            }

            setLoading(true)
            collections.users
              .doc(user.uid)
              .update(update)
              .then(history.goBack)
              .catch(e => {
                alert(e.message)
                setLoading(false)
              })
          }}
        >
          {loading ? 'Saving...' : 'Save'}
        </button>
      </div>
    </div>
  )
}
