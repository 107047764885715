import { map, orderBy } from 'lodash'
import usePlatform from './usePlatform'

export default () => {
  const { categories } = usePlatform()

  return orderBy(
    map(categories, (name, id) => {
      return {
        name,
        id
      }
    }),
    'name',
    'asc'
  )
}
