import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import Icon from '@mdi/react'
import { chunk, groupBy, map, range, uniq } from 'lodash'
import { useMemo, useState } from 'react'
import Campaign from '../../types/Campaign'
import Ticket from '../../types/Ticket'
import { useUsers } from '../../utils/getUsers'
import theme from '../../utils/theme'

const Entry = ({
  tickets,
  number,
  campaign
}: {
  tickets: Ticket[]
  number: number
  campaign: Campaign
}) => {
  const userIds = useMemo(() => {
    return uniq(tickets.map(t => t.uid))
  }, [tickets])

  const [open, setOpen] = useState(false)
  const users = useUsers(userIds)

  let sharesTaken = 0
  for (const ticket of tickets) {
    sharesTaken += ticket.shares.length
  }
  const taken = sharesTaken === campaign.sharesPerSpot

  const firstUser = users.find(u => u.id === tickets[0].uid)

  return (
    <div className=' border-gray-200 border rounded mt-2 '>
      <div
        className='flex items-center hover:bg-gray-200 p-2 cursor-pointer'
        onClick={() => {
          setOpen(!open)
        }}
      >
        <div
          style={{
            backgroundColor: taken
              ? theme.board.purchased
              : theme.board.partial
          }}
          className={`h-10 w-10 rounded-sm flex items-center justify-center text-white text-sm font-bold`}
        >
          {number}
        </div>
        <div className='flex-1 pl-2 text-sm'>
          {userIds.length === 0
            ? 'Anonymous'
            : tickets.length > 1
            ? 'Multiple Users'
            : firstUser && !firstUser.anonymous
            ? `${firstUser.firstName} ${firstUser.lastName}`
            : 'Anonymous'}
        </div>
        <div className='flex-1 pl-2 text-sm'>
          {sharesTaken}/{campaign.sharesPerSpot}
        </div>
        <div className='flex-1 pl-2 text-sm'>
          ${sharesTaken * campaign.pricePerShare}
        </div>
        <div className='h-10 w-10 pl-2 flex items-center justify-center'>
          {tickets.length > 0 && (
            <Icon
              path={open ? mdiChevronUp : mdiChevronDown}
              color='black'
              size={1}
            />
          )}
        </div>
      </div>
      {open && (
        <div className='mt-2 p-2'>
          {tickets.map(ticket => {
            const user = users.find(u => u.id === ticket.uid)
            return (
              <div key={ticket.id} className='flex items-center'>
                <div className='w-10'></div>
                <div className='flex-1 pl-2 text-sm'>
                  {user && !user.anonymous
                    ? `${user.firstName} ${user.lastName}`
                    : 'Anonymous'}
                </div>
                <div className='flex-1 pl-2 text-sm'>
                  {ticket.shares.length}/{campaign.sharesPerSpot}
                </div>
                <div className='flex-1 pl-2 text-sm'>
                  ${ticket.shares.length * campaign.pricePerShare}
                </div>
                <div className='w-10 pl-2'></div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ({
  campaign,
  tickets: _tickets,
  winners
}: {
  campaign: Campaign
  tickets: Ticket[]
  winners?: number[]
}) => {
  const [hundred, setHundred] = useState(0)

  const [start, end] = useMemo(() => {
    return [hundred * 100 + 1, hundred * 100 + 100]
  }, [hundred])

  const tickets = _tickets.filter(t => {
    if (winners) {
      return winners.includes(t.number)
    }
    return t.number >= start && t.number <= end
  })

  const rows = chunk(range(1, campaign.spots + 1), 100)
  const groupedTickets = groupBy(tickets, 'number')

  return (
    <div className='p-4 rounded border border-gray-200 bg-white'>
      {!winners && (
        <div className='flex justify-between items-center'>
          <div className='text-xl font-bold'>Entries</div>
          <select
            className='p-2'
            onChange={e => {
              setHundred(parseInt(e.target.value))
            }}
          >
            {rows.map((row, index) => {
              return (
                <option key={index} value={index}>
                  {row[0]}-{row[row.length - 1]}
                </option>
              )
            })}
          </select>
        </div>
      )}
      <div className='flex items-center px-2 mt-3'>
        <div className='w-10'></div>
        <div className='flex-1 pl-2 text-xs text-gray-700'>
          PURCHASED BY
        </div>
        <div className='flex-1 pl-2 text-xs text-gray-700'>SHARES</div>
        <div className='flex-1 pl-2 text-xs text-gray-700'>COST</div>
        <div className='w-10 pl-2'></div>
      </div>
      {map(groupedTickets, (tickets, number) => {
        return (
          <Entry
            tickets={tickets}
            number={parseInt(number)}
            campaign={campaign}
            key={'number-' + number}
          />
        )
      })}
    </div>
  )
}
