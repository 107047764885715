import Campaigns from './Campaigns'

export default () => {
  return (
    <div className='container my-4'>
      <div className='text-4xl text-colored'>Thank you for applying</div>
      <p className='text-sm text-gray-800 mt-2'>
        We are reviewing your application and will notify you of our
        decision within 72 hours. In the meantime, you can participate in
        some of our popular raffles.
      </p>
      <div className='mt-4'></div>
      <Campaigns limit={4} />
    </div>
  )
}
