import { FC } from 'react'

const SectionHeader: FC = ({ children }) => {
  return (
    <div>
      <div className='h-1 w-64 bg-primary mb-2' />
      <h3 className='text-4xl text-colored font-bold'>{children}</h3>
    </div>
  )
}

export default SectionHeader
