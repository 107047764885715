import firebase from 'firebase/app'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import createTitle from '../../utils/createTitle'
import { firestore } from '../../utils/firebase'
import PortalTabTitle from './PortalTabTitle'

export default () => {
  const user = useRecoilValue(userAtom)
  const [docs, setDocs] = useState<
    firebase.firestore.QueryDocumentSnapshot[]
  >([])

  useEffect(() => {
    if (!user) {
      return
    }

    const unsub = firestore
      .collection('users')
      .doc(user.id)
      .collection('notifications')
      .orderBy('createdAt', 'desc')
      .onSnapshot(snap => {
        setDocs(snap.docs)
      })

    return () => unsub()
  }, [user])

  useEffect(() => {
    docs.forEach(doc => {
      if (!doc.get('read')) {
        doc.ref.update({ read: true })
      }
    })
  }, [docs])

  return (
    <div>
      <Helmet>
        <title>{createTitle('Notifications')}</title>
      </Helmet>
      <PortalTabTitle title='Notifications' icon='bell' />
      <div className='mt-4 bg-white rounded shadow'>
        {docs.map(doc => {
          const notification = doc.data() as Notification
          return (
            <Link to={notification.path} className='Link' key={doc.id}>
              <div className='p-4 hover:bg-gray-100'>
                <h3 className='font-bold'>{notification.title}</h3>
                <p className='text-sm'>{notification.message}</p>
              </div>
              <div className='w-full h-px bg-gray-50' />
            </Link>
          )
        })}
      </div>
    </div>
  )
}

interface Notification {
  createdAt: firebase.firestore.Timestamp
  message: string
  path: string
  read: boolean
  title: string
}
