import firebase from 'firebase/app'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import createTitle from '../../utils/createTitle'
import { auth } from '../../utils/firebase'
import TextInput from '../TextInput'

export default () => {
  const [current, setCurrent] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirm, setConfirm] = useState('')
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  return (
    <div>
      <Helmet>
        <title>{createTitle('Update Password')}</title>
      </Helmet>
      <div className='text-2xl'>Update Password</div>
      <div className='mt-4 bg-white p-4 rounded shadow'>
        <TextInput
          type='password'
          label='Current Password'
          onTextChange={setCurrent}
        />
        <TextInput
          className='mt-2'
          type='password'
          label='New Password'
          onTextChange={setNewPassword}
        />
        <TextInput
          className='mt-2'
          type='password'
          label='Confirm New Password'
          onTextChange={setConfirm}
        />
      </div>
      <div className='mt-4 flex justify-between items-center'>
        <Link className='Link' to='/portal/settings'>
          <button className='button button-outline'>Cancel</button>
        </Link>
        <button
          disabled={saving || newPassword !== confirm}
          className='button button-yellow cursor-pointer'
          onClick={async () => {
            const user = auth.currentUser
            if (!user || !user.email) {
              return
            }

            setSaving(true)
            try {
              const credential =
                firebase.auth.EmailAuthProvider.credential(
                  user.email,
                  current
                )
              await user.reauthenticateWithCredential(credential)
              await user.updatePassword(newPassword)
              history.goBack()
            } catch (e) {
              alert(e.message)
              setSaving(false)
            }
          }}
        >
          {saving ? 'Saving...' : 'Save'}
        </button>
      </div>
    </div>
  )
}
