import { mdiEmail, mdiOpenInNew, mdiPhone } from '@mdi/js'
import Icon from '@mdi/react'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router'
import Campaign from '../types/Campaign'
import Charity from '../types/Charity'
import createTitle from '../utils/createTitle'
import { collections } from '../utils/firebase'
import getActiveCampaigns from '../utils/getActiveCampaigns'
import CampaignList from './CampaignList'
import CharityTitleWithFavorite from './CharityPage/CharityTitleWithFavorite'
import PageLoading from './PageLoading'

const imageSize = 100

const InfoItem = ({ icon, value }: { icon: string; value: string }) => {
  return (
    <div className='flex items-center'>
      <Icon color='grey' size='22px' path={icon} />
      <span className='ml-3'>{value}</span>
    </div>
  )
}

export default () => {
  const { id } = useParams<{ id: string }>()
  const [charity, setCharity] = useState<Charity>()
  const [campaigns, setCampaigns] = useState<Campaign[]>([])

  useEffect(() => {
    collections.users.doc(id).onSnapshot(snap => {
      setCharity(snap.data()?.charity)
    })

    getActiveCampaigns(id, 3).then(setCampaigns)
  }, [id])

  if (!charity) {
    return <PageLoading />
  }

  return (
    <div className='my-5 container'>
      <Helmet>
        <title>{createTitle(charity.name)}</title>
      </Helmet>
      <div className='grid grid-cols-12 gap-4'>
        <div className='col-span-12 md:col-span-5'>
          <img
            src={charity.imageUrl}
            alt='Charity'
            style={{
              height: imageSize,
              width: imageSize,
              borderRadius: imageSize / 2,
              objectFit: 'cover'
            }}
          />
          <div className='mt-2'></div>
          <CharityTitleWithFavorite charity={charity} id={id} />
          <div className='mt-2'></div>
          <InfoItem icon={mdiPhone} value={charity.phone} />
          <div className='mt-1'></div>
          <InfoItem icon={mdiEmail} value={charity.email} />
          <div className='mt-1'></div>
          <InfoItem icon={mdiOpenInNew} value={charity.url} />
        </div>
        <div className='col-span-12 md:col-span-7'>
          <h2 className='text-2xl font-bold'>Who we are</h2>
          <p className='mt-3'>{charity.description}</p>
        </div>
      </div>
      {campaigns.length > 0 && (
        <div className='mt-6'>
          <h3 className='text-xl mb-2 font-bold'>Active Campaigns</h3>
          <CampaignList campaigns={campaigns} />
        </div>
      )}
    </div>
  )
}
