import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import Campaign from '../types/Campaign'
import Category from '../types/Category'
import FAQ from '../types/FAQ'
import Ticket from '../types/Ticket'
import User from '../types/User'

firebase.initializeApp({
  apiKey: 'AIzaSyDGBFrxIMHVTRxiVIx65_cOWF2kn-thPpE',
  authDomain: 'mumoin-8e715.firebaseapp.com',
  projectId: 'mumoin-8e715',
  storageBucket: 'mumoin-8e715.appspot.com',
  messagingSenderId: '893317205397',
  appId: '1:893317205397:web:36c26b72a6ef3b9ddf3a9c'
})

export const firestore = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
export const functions = firebase.functions()

const campaigns = firestore
  .collection('campaigns')
  .withConverter<Campaign>({
    fromFirestore: doc => {
      const data = doc.data() as Omit<Campaign, 'id' | 'goal'>

      return {
        id: doc.id,
        ...data,
        goal: data.spots * data.sharesPerSpot * data.pricePerShare
      }
    },
    toFirestore: (campaign: Campaign) => {
      const data: any = { ...campaign }
      delete data.id
      delete data.charity
      return data
    }
  })

export const collections = {
  campaigns: campaigns,
  categories: firestore.collection('categories').withConverter<Category>({
    fromFirestore: doc => {
      return {
        id: doc.id,
        ...(doc.data() as Omit<Category, 'id'>)
      }
    },
    toFirestore: (category: Category) => {
      const data: any = { ...category }
      delete data.id
      return data
    }
  }),
  faqs: firestore.collection('faqs').withConverter<FAQ>({
    fromFirestore: doc => {
      return {
        id: doc.id,
        ...(doc.data() as Omit<FAQ, 'id'>)
      }
    },
    toFirestore: (category: FAQ) => {
      const data: any = { ...category }
      delete data.id
      return data
    }
  }),
  tickets: (campaignId: string) => {
    return campaigns
      .doc(campaignId)
      .collection('tickets')
      .withConverter<Ticket>({
        fromFirestore: doc => {
          const data = doc.data() as Omit<Ticket, 'id' | 'createdAt'> & {
            createdAt: firebase.firestore.Timestamp
          }
          return {
            ...data,
            id: doc.id,
            createdAt: data.createdAt.toDate()
          }
        },
        toFirestore: (category: Omit<Ticket, 'id'>) => {
          const data: any = {
            ...category,
            createdAt: firebase.firestore.FieldValue.serverTimestamp()
          }
          delete data.id
          return data
        }
      })
  },
  users: firestore.collection('users').withConverter<User>({
    fromFirestore: doc => {
      return {
        id: doc.id,
        ...(doc.data() as Omit<User, 'id'>)
      }
    },
    toFirestore: (user: User) => {
      const data: any = { ...user }
      delete data.id
      return data
    }
  })
}
