import React, { useEffect, useState } from 'react'
import Campaign from '../types/Campaign'
import algolia from '../utils/algolia'
import getCampaigns from '../utils/getCampaigns'
import CampaignList from './CampaignList'
import PageLoading from './PageLoading'

export default ({ limit = 20 }: { limit?: number }) => {
  const [loading, setLoading] = useState(false)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])

  useEffect(() => {
    setLoading(true)

    algolia.campaigns
      .search('', {
        length: 3,
        filters: `endsAt > ${Date.now()} AND startsAt < ${Date.now()} AND finishedAt < 1`
      })
      .then(async result => {
        const campaignIds = result.hits.map(hit => hit.objectID)
        const campaigns = await getCampaigns(campaignIds)
        setCampaigns(campaigns)
      })
      .finally(() => setLoading(false))
  }, [limit])

  if (loading) {
    return <PageLoading />
  }

  return <CampaignList campaigns={campaigns} />
}
