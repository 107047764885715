import { useCart } from '../../atoms/cartAtom'
import icCartFull from './ic-cart-full.svg'
import icCart from './ic-cart.svg'

export default () => {
  const cart = useCart()

  return (
    <img
      className='h-10 w-10 ml-3'
      src={cart.length > 0 ? icCartFull : icCart}
      alt='Notifications'
    />
  )
}
