import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import Campaign from '../../types/Campaign'
import createTitle from '../../utils/createTitle'
import { collections } from '../../utils/firebase'
import PortalRaffleItem from './PortalRaffleItem'

export default () => {
  const user = useRecoilValue(userAtom)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])

  useEffect(() => {
    if (!user) {
      return
    }

    const unsubscribe = collections.campaigns
      .where('uid', '==', user.id)
      .orderBy('startsAt', 'desc')
      .onSnapshot(snap => {
        setCampaigns(snap.docs.map(d => d.data()))
      })

    return () => unsubscribe()
  }, [user])

  return (
    <div>
      <Helmet>
        <title>{createTitle('My Campaigns')}</title>
      </Helmet>
      <div className='flex items-center justify-between'>
        <h2 className='text-2xl'>My Campaigns</h2>
        <Link className='Link' to='/portal/create-raffle'>
          <button className='button button-yellow'>Add New</button>
        </Link>
      </div>
      <div className='mt-4 grid gap-3 grid-cols-2'>
        {campaigns.map(campaign => {
          return <PortalRaffleItem key={campaign.id} campaign={campaign} />
        })}
      </div>
    </div>
  )
}
