import { mdiHeart, mdiHeartOutline } from '@mdi/js'
import Icon from '@mdi/react'
import firebase from 'firebase/app'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import Charity from '../../types/Charity'
import { collections } from '../../utils/firebase'

export default ({ charity, id }: { charity: Charity; id: string }) => {
  const user = useRecoilValue(userAtom)

  const isFavorite = useMemo(() => {
    return !!user?.favoriteCharityIds?.includes(id)
  }, [id, user?.favoriteCharityIds])

  return (
    <div className='flex items-center'>
      {user && (
        <div
          className='p-2 cursor-pointer mr-2'
          onClick={() => {
            const ref = collections.users.doc(user.id)
            if (isFavorite) {
              ref.update({
                favoriteCharityIds:
                  firebase.firestore.FieldValue.arrayRemove(id)
              })
            } else {
              ref.update({
                favoriteCharityIds:
                  firebase.firestore.FieldValue.arrayUnion(id)
              })
            }
          }}
        >
          <Icon
            path={isFavorite ? mdiHeart : mdiHeartOutline}
            color={'red'}
            size={1}
          />
        </div>
      )}
      <div className='text-xl font-bold'>{charity.name}</div>
    </div>
  )
}
