import { mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import { trim } from 'lodash'
import { useEffect, useState } from 'react'
import Modal from 'react-modal'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import constants from '../../utils/constants'
import { firestore } from '../../utils/firebase'
import TextInput from '../TextInput'
import icFacebook from '../ic-facebook-color.svg'
import icInstagram from '../ic-instagram-color.svg'
import icLinkedIn from '../ic-linkedin-color.svg'
import icTwitter from '../ic-twitter-color.svg'
import icInvitationsSent from './ic-invitations-sent.svg'

export default ({
  isOpen,
  dismiss: _dismiss,
  campaignId,
  number,
  ticketId
}: {
  isOpen: boolean
  dismiss: () => void
  campaignId: string
  number?: number
  ticketId?: string
}) => {
  const [emails, setEmails] = useState<string[]>([])
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)
  const user = useRecoilValue(userAtom)
  const [success, setSuccess] = useState(false)

  const reserveUrl = `${constants.webURL}/campaigns/${campaignId}/${number}?ref=${ticketId}`

  const dismiss = () => {
    _dismiss()
    setInput('')
    setEmails([])
    setSuccess(false)
  }

  useEffect(() => {
    if (input.length < 6) {
      return
    }

    if (input[input.length - 1] === ',') {
      setEmails([...emails, input.substr(0, input.length - 1)])
      setInput('')
    }
  }, [input, emails])

  return (
    <Modal
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          margin: 0,
          padding: 0,
          width: '90%',
          maxWidth: 600
        }
      }}
      isOpen={isOpen}
      onRequestClose={dismiss}
    >
      <div className='h-full w-full bg-gray-50'>
        <div className='flex justify-end'>
          <div
            className='p-3 rounded-full hover:bg-gray-200'
            onClick={dismiss}
          >
            <Icon path={mdiClose} size={0.75} />
          </div>
        </div>
        {success ? (
          <div className='p-3'>
            <div className='text-xl font-bold text-center'>
              Awesome! Your invitations have been sent.
            </div>
            {ticketId ? (
              <div className='text-sm text-center'>
                This number will be reserved for you and your friends for
                the next 48 hours. If you don't complewtely purchase the
                raffle ticket in the time provided it will be open to the
                public.
              </div>
            ) : (
              <div className='text-sm text-center'>Good luck!</div>
            )}
            <div className='p-4 flex justify-center'>
              <img src={icInvitationsSent} alt='Invitations sent!' />
            </div>
          </div>
        ) : (
          <div className='p-3'>
            <div className='text-xl font-bold text-center'>
              {ticketId
                ? 'Invite personal contacts to join your number purchase'
                : 'Invite personal contacts to join your raffle'}
            </div>
            <div className='text-sm text-center'>
              Press enter to add an email
            </div>
            <div className='flex justify-center'></div>

            <div
              className='bg-white shadow rounded pb-2 pl-2 mt-3'
              style={{ minHeight: 120 }}
            >
              <div className='flex flex-wrap items-center'>
                {emails.map((email, index) => {
                  return (
                    <div
                      onClick={() => {
                        if (emails.length === 1) {
                          setEmails([])
                        } else setEmails(emails.splice(index, 1))
                      }}
                      className='text-sm p-1 rounded-full bg-gray-200 mr-2 mt-2 hover:bg-gray-300 cursor-pointer'
                      key={index.toString()}
                    >
                      {email}
                    </div>
                  )
                })}
                <div className='w-48 mr-2 mt-2'>
                  <input
                    className='text-input'
                    type='email'
                    placeholder='Type an email address...'
                    value={input}
                    onChange={e => setInput(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        setEmails([...emails, input])
                        setInput('')
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='mt-3 flex justify-center'>
              <button
                disabled={loading}
                className='button button-green'
                onClick={() => {
                  if (emails.length === 0) {
                    window.alert(
                      "You haven't entered any email addresses to invite!"
                    )
                    return
                  }
                  setLoading(true)
                  firestore
                    .collection('mail')
                    .add({
                      to: emails,
                      message: {
                        subject: "You've been invited to a raffle!",
                        text: ticketId
                          ? `Hello,\n\n${
                              user
                                ? `${user.firstName} ${user.lastName}`
                                : 'A Fundraise Club user'
                            } has invited you to purchase shares in a raffle spot that they have reserved. Act quick because this reservation only lasts 48 hours. After the reservation expires, the shares will be available to the public!\n\nTo access the reservation, use this link: ${
                              constants.webURL
                            }/campaigns/${campaignId}/${number}?ref=${ticketId}\n\nThank you,\nThe Fundraise Club Team`
                          : `Hello,\n\n${
                              user?.charity
                                ? user.charity.name
                                : 'A Fundraise Club charity'
                            } has invited you to purchase shares in a raffle spot. Act quick because these spots may go quick!\n\nTo access the raffle, use this link: ${
                              constants.webURL
                            }/campaigns/${campaignId}\n\nThank you,\nThe Fundraise Club Team`
                      }
                    })
                    .then(() => {
                      setSuccess(true)
                    })
                    .catch(e => {
                      alert(e.message)
                    })
                    .finally(() => {
                      setLoading(false)
                    })
                }}
              >
                {loading ? 'Please wait...' : 'Invite by Email'}
              </button>
            </div>
            <div className='flex items-center w-full mt-4'>
              <div className='flex-1 bg-gray-500 h-px'></div>
              <div className='mx-3'>or share via Social Media</div>
              <div className='flex-1 bg-gray-500 h-px'></div>
            </div>
            <div className=' items-center mt-3 justify-center hidden'>
              <a
                href={`https://www.facebook.com/dialog/feed?app_id=${
                  constants.facebookAppId
                }&quote=${encodeURIComponent(
                  'Check out this awesome raffle on Fundraise Club!'
                )}&link=${encodeURIComponent(
                  `${constants.webURL}/campaigns/${campaignId}?ref=${ticketId}`
                )}&display=popup`}
                target='_blank'
                rel='noreferrer'
                className='bg-white rounded-full shadow w-10 h-10 hover:bg-gray-100 flex items-center justify-center'
              >
                <img src={icFacebook} alt='Facebook' />
              </a>
              <div className='w-4'></div>
              <button className='bg-white rounded-full shadow w-10 h-10 hover:bg-gray-100 flex items-center justify-center'>
                <img src={icInstagram} alt='Instagram' />
              </button>
              <div className='w-4'></div>
              <button className='bg-white rounded-full shadow w-10 h-10 hover:bg-gray-100 flex items-center justify-center'>
                <img src={icLinkedIn} alt='LinkedIn' />
              </button>
              <div className='w-4'></div>
              <button className='bg-white rounded-full shadow w-10 h-10 hover:bg-gray-100 flex items-center justify-center'>
                <img src={icTwitter} alt='Twitter' />
              </button>
            </div>
            <TextInput
              label='Share this link on any social media:'
              disabled
              value={
                ticketId
                  ? reserveUrl
                  : `${constants.webURL}/campaigns/${campaignId}`
              }
            />
            <label className='text-sm' htmlFor='#uploadCsvInput'>
              Upload CSV with emails
            </label>
            <br />
            <input
              type='file'
              id='uploadCsvInput'
              accept='.csv'
              onChange={e => {
                const files = e.target.files

                console.log(files)

                if (!files) {
                  return
                }

                const file = files.item(0)
                if (!file) {
                  return
                }

                console.log(file)

                const reader = new FileReader()
                //@ts-ignore
                reader.onload = event => {
                  const result = event.target?.result
                  if (typeof result === 'string') {
                    const newEmail: string[] = []
                    const lines = result.split('\n')
                    lines.forEach(line => {
                      const parts = line.split(',')
                      parts.forEach(part => {
                        const value = trim(trim(part, '"'), "'")
                        if (validateEmail(value)) {
                          newEmail.push(value)
                        }
                      })
                    })
                    setEmails(newEmail)
                  }
                }

                reader.readAsText(file)
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
