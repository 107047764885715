import { groupBy, map } from 'lodash'
import moment from 'moment'
import Campaign from './Campaign'
import Ticket from './Ticket'

interface SpotState {
  sharesTaken: number
  reservedUntil?: Date
  tickets: Ticket[]
}

export default class BoardState {
  state: Record<number, SpotState>
  fullyOpen: number
  partiallyOpen = 0
  fullyTaken = 0

  constructor(tickets: Ticket[], { spots, sharesPerSpot }: Campaign) {
    this.state = {}
    this.fullyOpen = spots

    const groupedTickets = groupBy(tickets, 'number')
    map(groupedTickets, (tickets, number: number) => {
      let sharesTaken = 0
      let reservedUntil: Date | undefined = undefined
      for (const t of tickets) {
        sharesTaken += t.shares.length
        const tReservedUntil = moment(t.createdAt).add(1, 'day')
        if (t.syndicate && moment().isBefore(tReservedUntil)) {
          reservedUntil = tReservedUntil.toDate()
        }
      }
      this.state[number] = {
        sharesTaken,
        reservedUntil,
        tickets
      }
    })

    map(this.state, ({ sharesTaken }) => {
      if (sharesTaken > 0) {
        this.fullyOpen--
        if (sharesTaken === sharesPerSpot) {
          this.fullyTaken++
        } else {
          this.partiallyOpen++
        }
      }
    })
  }

  sharesTaken(number: number) {
    return this.getSpot(number)?.sharesTaken || 0
  }

  getSpot(number: number) {
    const spotState: SpotState | undefined = this.state[number]
    if (!spotState) {
      return undefined
    }
    return spotState
  }

  getTickets(number: number) {
    return this.getSpot(number)?.tickets || []
  }

  getReservedUntil(number: number) {
    return this.getSpot(number)?.reservedUntil
  }
}
