import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import Charity from '../../types/Charity'
import constants from '../../utils/constants'
import createTitle from '../../utils/createTitle'
import { collections, storage } from '../../utils/firebase'
import { triggerEmail } from '../../utils/triggerEmail'
import CharityApplyCompletePage from '../CharityApplyCompletePage'
import TextInput from '../TextInput'

export default () => {
  const [charity, setCharity] = useState<Charity>({
    description: '',
    name: '',
    phone: '',
    email: '',
    url: '',
    imageUrl: '',
    facebook: '',
    instagram: '',
    license: ''
  })
  const [saving, setSaving] = useState(false)
  const [file, setFile] = useState<any>()
  const user = useRecoilValue(userAtom)
  const history = useHistory()

  useEffect(() => {
    if (user?.charity) {
      setCharity(user.charity)
    }
  }, [user])

  const displayImage = file ? URL.createObjectURL(file) : charity.imageUrl
  if (user?.charityState === 'pending') {
    return <CharityApplyCompletePage />
  }

  return (
    <div>
      <h1 className='text-2xl'>Charity Profile</h1>
      <Helmet>
        <title>{createTitle('Charity Profile')}</title>
      </Helmet>
      <form
        onSubmit={async e => {
          e.preventDefault()

          if (!user) {
            return
          }
          if (!displayImage) {
            return window.alert('Please select an image.')
          }

          setSaving(true)
          let imageUrl = charity.imageUrl

          // TODO deal with resized images
          if (file) {
            const id = collections.campaigns.doc().id
            const ref = await storage
              .ref('/images')
              .child(id)
              .child('original')
            await ref.put(file)
            imageUrl = await ref.getDownloadURL()
            console.log('uploaded image to', imageUrl)
          }

          const update: any = { charity: { ...charity, imageUrl } }
          if (!user.charityState) {
            update.charityState = 'pending'
          }

          collections.users
            .doc(user.id)
            .update(update)
            .then(() => {
              if (user.charityState) {
                history.replace('/portal/dashboard')
              }
              if (update.charityState === 'pending') {
                triggerEmail({
                  message: {
                    subject: `Charity Approval Pending (${charity.name})`,
                    text: `You have a pending charity request. Please visit ${constants.webURL}/portal/admin/charities to view the request.`
                  },
                  toUids: ['tdz6tk1vaXhuM2lmNFI3s2rRBKy1']
                })
              }
            })
            .finally(() => setSaving(false))
        }}
      >
        <div className='bg-white rounded shadow p-4 mt-3'>
          {displayImage && (
            <div className='flex justify-center'>
              <img
                src={displayImage}
                alt={charity.name}
                className='rounded-lg h-32 w-32 object-cover'
              />
            </div>
          )}
          <div className='grid grid-cols-2 gap-4 mt-3'>
            <div className='col-span-2'>
              <div className='mb-1 text-xs text-gray-500'>
                Cover Image
                {!charity.imageUrl ? (
                  <span className='text-red-600'> *</span>
                ) : null}
              </div>
              <input
                required={!charity.imageUrl}
                type='file'
                accept='image/*'
                onChange={e => {
                  const { files } = e.target
                  if (files) {
                    setFile(files[0])
                  }
                }}
              />
            </div>

            <TextInput
              required
              className='col-span-2'
              type='text'
              value={charity.name}
              label='Charity Name'
              onTextChange={name =>
                setCharity({
                  ...charity,
                  name
                })
              }
            />
            <TextInput
              required
              className='col-span-2'
              type='text'
              value={charity.license || ''}
              label='Charity License #'
              onTextChange={license =>
                setCharity({
                  ...charity,
                  license
                })
              }
            />
            <TextInput
              required
              className='col-span-2 md:col-span-1'
              label='Phone Number'
              value={charity.phone}
              onTextChange={phone =>
                setCharity({
                  ...charity,
                  phone
                })
              }
            />
            <TextInput
              required
              className='col-span-2 md:col-span-1'
              label='Email'
              type='email'
              value={charity.email}
              onTextChange={email =>
                setCharity({
                  ...charity,
                  email
                })
              }
            />
            <TextInput
              required
              className='col-span-2'
              label='Website'
              value={charity.url}
              onTextChange={url =>
                setCharity({
                  ...charity,
                  url
                })
              }
            />
            <TextInput
              className='col-span-2'
              label='Facebook Profile'
              value={charity.facebook}
              onTextChange={facebook =>
                setCharity({
                  ...charity,
                  facebook
                })
              }
            />
            <TextInput
              className='col-span-2'
              label='Instagram Profile'
              value={charity.instagram}
              onTextChange={instagram =>
                setCharity({
                  ...charity,
                  instagram
                })
              }
            />
            <div className='col-span-2'>
              <div className='text-xs text-gray-500'>
                Tell us what your charity is all about
                <span className='text-red-600'> *</span>
              </div>
              <textarea
                required
                className='text-input'
                rows={5}
                value={charity.description}
                onChange={e => {
                  setCharity({
                    ...charity,
                    description: e.target.value
                  })
                }}
              />
            </div>
          </div>
        </div>
        <div className='mt-3 flex justify-between'>
          <button
            className='button button-outline'
            onClick={history.goBack}
          >
            Cancel
          </button>

          <button
            className='button button-green px-5'
            disabled={saving}
            type='submit'
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  )
}
