import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import Campaign from '../../types/Campaign'
import Ticket from '../../types/Ticket'
import User from '../../types/User'
import createTitle from '../../utils/createTitle'
import { collections } from '../../utils/firebase'
import Tabs from '../Tabs'

interface Participation {
  campaign: Campaign
  tickets: Ticket[]
}

export default () => {
  const [tab, setTab] = useState(0)
  const user = useRecoilValue(userAtom)
  const [participations, setParticipations] = useState<Participation[]>([])

  async function getCampaigns(user: User) {
    if (!user.campaignIds) {
      return
    }

    const results = await Promise.all(
      user.campaignIds.map(async id => {
        const [campaignSnap, ticketsSnap] = await Promise.all([
          collections.campaigns.doc(id).get(),
          collections.tickets(id).where('uid', '==', user.id).get()
        ])

        return {
          campaign: campaignSnap.data(),
          tickets: ticketsSnap.docs.map(snap => {
            return snap.data()
          })
        }
      })
    )

    const participation: Participation[] = []
    results.forEach(({ campaign, tickets }) => {
      if (campaign) {
        participation.push({ campaign, tickets })
      }
    })

    setParticipations(participation)
  }

  useEffect(() => {
    if (user) getCampaigns(user)
  }, [user])

  const [active, settled] = useMemo(() => {
    const now = Date.now()
    return [
      participations.filter(({ campaign }) => {
        return campaign.endsAt.toMillis() > now
      }),
      participations.filter(({ campaign }) => {
        return campaign.endsAt.toMillis() < now
      })
    ]
  }, [participations])

  const campaignsToShow = tab === 0 ? active : settled

  return (
    <div>
      <Helmet>
        <title>{createTitle('Campaign Book')}</title>
      </Helmet>
      <div className='px-6 pt-6 bg-dark-blue text-white'>
        <div className='text-2xl'>Campaign Book</div>
        <Tabs
          tabs={['Active', 'Settled']}
          activeIndex={tab}
          setIndex={setTab}
        />
      </div>
      <div className='p-3 grid grid-cols-12 gap-4'>
        {campaignsToShow.map(({ campaign, tickets }) => {
          let totalShares = 0
          tickets.forEach(t => {
            totalShares += t.shares.length
          })
          return (
            <Link
              to={`/campaigns/${campaign.id}`}
              className='Link col-span-12 md:col-span-6 lg:col-span-4'
            >
              <div className=' rounded shadow hover:shadow-xl'>
                <img
                  src={campaign.imageUrl}
                  alt={campaign.title}
                  className='object-cover h-28 w-full'
                />
                <div className='p-3 bg-white'>
                  <div className='font-bold'>{campaign.title}</div>
                  <div className='mt-1 flex justify-between items-center'>
                    <div className='text-sm text-gray-700'>Paid</div>
                    <div className='text-sm font-bold'>
                      ${totalShares * campaign.pricePerShare}
                    </div>
                  </div>
                  <div className='flex justify-between items-center mt-1'>
                    <div className='text-sm text-gray-700'>Potential</div>
                    <div className='text-sm font-bold'>$1000</div>
                  </div>
                </div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
