import { keys, map } from 'lodash'
import { Link } from 'react-router-dom'
import { useCart } from '../../atoms/cartAtom'
import Campaign from '../../types/Campaign'

export default ({ campaign }: { campaign: Campaign }) => {
  const cart = useCart()

  const items = cart.filter(item => item.campaignId === campaign.id)
  if (items.length === 0) {
    return null
  }

  const sharesPerSpot: Record<string, number> = {}
  items.forEach(({ shares: { length }, number }) => {
    if (sharesPerSpot[number]) {
      sharesPerSpot[number] += length
    } else sharesPerSpot[number] = length
  })

  let totalAmount = 0
  let fullSpots = 0,
    partialSpots = 0
  map(sharesPerSpot, numShares => {
    if (numShares >= campaign.sharesPerSpot) {
      fullSpots++
    } else if (numShares > 0) {
      partialSpots++
    }
    totalAmount += campaign.pricePerShare * numShares
  })

  const pctWin = keys(sharesPerSpot).length / campaign.spots

  return (
    <div className='col-span-12 grid grid-cols-12 items-center gap-4 p-4 rounded border border-gray-200 bg-white'>
      <div className='text-xl font-bold col-span-12'>In Your Cart</div>
      <div className='col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2'>
        <div className='text-sm text-gray-700'>Full Spots</div>
        <div className='font-bold text-xl'>{fullSpots}</div>
      </div>
      <div className='col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2'>
        <div className='text-sm text-gray-700'>Partial Spots</div>
        <div className='font-bold text-xl'>{partialSpots}</div>
      </div>
      <div className='col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2'>
        <div className='text-sm text-gray-700'>Chance</div>
        <div className='font-bold text-xl'>{Math.ceil(pctWin * 100)}%</div>
      </div>
      <div className='col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2'>
        <div className='text-sm text-gray-700'>Purchasing</div>
        <div className='font-bold text-xl'>${totalAmount}</div>
      </div>
      <div className='col-span-12 lg:col-span-4'>
        <Link to='/cart'>
          <button className='button button-yellow w-full'>Checkout</button>
        </Link>
      </div>
    </div>
  )
}
