import Campaign from '../types/Campaign'
import { collections } from './firebase'
import getCharitiesForCampaigns from './getCharitiesForCampaigns'

export default async function (campaignIds: string[]) {
  const _campaigns = await Promise.all(
    campaignIds.map(id => {
      return collections.campaigns
        .doc(id)
        .get()
        .then(snap => {
          return snap.data()
        })
    })
  )

  const campaigns: Campaign[] = []
  for (const c of _campaigns) {
    if (c) {
      campaigns.push(c)
    }
  }

  return getCharitiesForCampaigns(campaigns)
}
