import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import Campaign from '../../types/Campaign'
import Ticket from '../../types/Ticket'
import createTitle from '../../utils/createTitle'
import { collections } from '../../utils/firebase'
import Entries from '../CampaignPage/Entries'
import CreateCampaignPage from '../CreateCampaignPage/CreateCampaignPage'
import Tabs from '../Tabs'
import PortalCampaignDashboard from './PortalCampaignDashboard'

export default () => {
  const { id } = useParams<{ id: string }>()
  const [campaign, setCampaign] = useState<Campaign>()
  const [tickets, setTickets] = useState<Ticket[]>([])
  const [tab, setTab] = useState(0)

  useEffect(() => {
    const unsub1 = collections.campaigns.doc(id).onSnapshot(snap => {
      setCampaign(snap.data())
    })

    const unsub2 = collections.tickets(id).onSnapshot(snap => {
      setTickets(snap.docs.map(d => d.data()))
    })

    return () => {
      unsub1()
      unsub2()
    }
  }, [id, setCampaign])

  if (!campaign) {
    return null
  }

  return (
    <div>
      <Helmet>
        <title>{createTitle(campaign.title)}</title>
      </Helmet>
      <div className='bg-dark-blue container pt-5 text-white'>
        <div className='text-2xl font-bold '>{campaign.title}</div>
        <Tabs
          activeIndex={tab}
          setIndex={setTab}
          tabs={['Dashboard', 'Details', 'Buyers', 'Winners']}
        />
      </div>
      <div className='container py-3'>
        {tab === 0 ? (
          <PortalCampaignDashboard campaign={campaign} tickets={tickets} />
        ) : tab === 1 ? (
          <CreateCampaignPage />
        ) : tab === 2 ? (
          <Entries campaign={campaign} tickets={tickets} />
        ) : tab === 3 ? (
          <Entries
            campaign={campaign}
            tickets={tickets}
            winners={campaign.winners || []}
          />
        ) : null}
      </div>
    </div>
  )
}
