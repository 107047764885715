import {
  mdiCash,
  mdiCharity,
  mdiChatQuestion,
  mdiCogs,
  mdiListStatus
} from '@mdi/js'
import Icon from '@mdi/react'
import { Helmet } from 'react-helmet'
import { Link, Route, Switch } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import createTitle from '../../utils/createTitle'
import theme from '../../utils/theme'
import AdminCategories from './AdminCategories'
import AdminCharities from './AdminCharities'
import AdminFaqs from './AdminFaqs'
import AdminPayouts from './AdminPayouts'
import AdminSettings from './AdminSettings'

const Item = ({
  title,
  description,
  path,
  icon
}: {
  title: string
  description: string
  path: string
  icon: string
}) => {
  return (
    <Link
      to={path}
      className='col-span-12 md:col-span-6 lg:col-span-4 rounded bg-white shadow p-4 flex items-center justify-between hover:bg-gray-100'
    >
      <div>
        <div className='font-bold'>{title}</div>
        <div className='mt-1 text-sm'>{description}</div>
      </div>
      <Icon size={1.5} path={icon} color={theme.primary} />
    </Link>
  )
}

export default () => {
  const user = useRecoilValue(userAtom)
  if (user?.role !== 'admin') {
    return null
  }

  return (
    <Switch>
      <Route exact path='/portal/admin/faqs' component={AdminFaqs} />
      <Route
        exact
        path='/portal/admin/categories'
        component={AdminCategories}
      />
      <Route
        exact
        path='/portal/admin/settings'
        component={AdminSettings}
      />
      <Route
        exact
        path='/portal/admin/charities'
        component={AdminCharities}
      />
      <Route exact path='/portal/admin/payouts' component={AdminPayouts} />
      <Route>
        <div>
          <Helmet>
            <title>{createTitle('Admin')}</title>
          </Helmet>
          <div className='text-2xl'>Admin</div>
          <div className='grid grid-cols-12 gap-4 mt-4'>
            <Item
              title="FAQ's"
              path='/portal/admin/faqs'
              icon={mdiChatQuestion}
              description='Manage the Frequently Asked Questions'
            />
            <Item
              title='Categories'
              path='/portal/admin/categories'
              icon={mdiListStatus}
              description='Manage the Campaign Categories'
            />
            <Item
              title='Settings'
              path='/portal/admin/settings'
              icon={mdiCogs}
              description='Fundraise Club platform configuration'
            />
            <Item
              title='Charities'
              path='/portal/admin/charities'
              icon={mdiCharity}
              description='View, approve, and reject charities.'
            />
            <Item
              title='Payouts'
              path='/portal/admin/payouts'
              icon={mdiCash}
              description='Pay out raffle winners and charities'
            />
          </div>
        </div>
      </Route>
    </Switch>
  )
}
