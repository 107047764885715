import { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import viewerAtom from '../atoms/viewerAtom'
import { firestore } from '../utils/firebase'

export default () => {
  const viewer = useRecoilValue(viewerAtom)
  const [endingIn, setEndingIn] = useState<string>()

  const ref = useMemo(() => {
    if (!viewer) {
      return
    }
    return firestore.collection('cards').doc(viewer.uid)
  }, [viewer])

  useEffect(() => {
    if (!ref) {
      return
    }

    const unsub = ref.onSnapshot(snap => {
      if (!snap.exists) {
        setEndingIn(undefined)
      } else {
        setEndingIn(snap.get('last4'))
      }
    })

    return () => unsub()
  }, [setEndingIn, ref])

  return endingIn
}
