import { collections } from './firebase'

export default async function (uid: string, limit: number) {
  const snap = await collections.campaigns
    .where('uid', '==', uid)
    .where('finishedAt', '==', false)
    .where('startsAt', '<=', new Date())
    .get()

  return snap.docs.map(doc => {
    return doc.data()
  })
}
