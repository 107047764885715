import { Link } from 'react-router-dom'

export default () => {
  return (
    <div className='flex justify-end'>
      <div className='bg-gray-300 flex h-20 items-center rounded-l-full px-10'>
        <div className='font-bold text-2xl'>
          Have any questions? We are here to help
        </div>
        <Link className='Link' to='/faqs'>
          <button className='button button-green ml-5'>FAQ's</button>
        </Link>
      </div>
    </div>
  )
}
