import qs from 'querystring'
import { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router-dom'
import useCategories from '../hooks/useCategories'
import Campaign from '../types/Campaign'
import algolia from '../utils/algolia'
import createTitle from '../utils/createTitle'
import getCampaigns from '../utils/getCampaigns'
import CampaignList from './CampaignList'
import TextInput from './TextInput'
import icRafflesEmpty from './ic-raffles-empty-state.svg'

interface Params {
  q?: string
  c?: string
  t?: string
}

async function doSearch({ q, c, t }: Params) {
  const filters: string[] = []
  if (c) {
    filters.push(`categoryIds:${c}`)
  }
  switch (t) {
    case 'live':
      filters.push(`startsAt < ${Date.now()}`)
      filters.push(`finishedAt = 0`)
      break
    case 'future':
      filters.push(`startsAt > ${Date.now()}`)
      break
    case 'finished':
      filters.push(`finishedAt > 0`)
      break
  }

  const results = await algolia.campaigns.search(q || '', {
    length: 10,
    filters: filters.join(' AND ')
  })
  const campaignIds = results.hits.map(hit => hit.objectID)
  return getCampaigns(campaignIds)
}

export default () => {
  const categories = useCategories()
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const location = useLocation()
  const history = useHistory()
  const [, setLoading] = useState(false)

  const params: { q?: string; c?: string; t?: string } = useMemo(() => {
    return qs.parse(location.search.replace('?', ''))
  }, [location])

  useEffect(() => {
    setLoading(true)
    doSearch(params)
      .then(setCampaigns)
      .finally(() => setLoading(false))
  }, [params])

  return (
    <div className='container py-4'>
      <Helmet>
        <title>
          {createTitle(
            params.t === 'live'
              ? 'Live Campaigns'
              : params.t === 'future'
              ? 'Future Campaigns'
              : params.t === 'finished'
              ? 'Finished Campaigns'
              : 'Campaigns'
          )}
        </title>
      </Helmet>
      <div className='col-span-12 lg:col-span-1'>
        <h1 className='text-3xl font-bold'>Campaigns</h1>
      </div>
      <div className='grid gap-4 grid-cols-12 mt-3 mb-6'>
        <select
          className='p-2 col-span-6 md:col-span-4 lg:col-span-3'
          value={params.c || 'no-category'}
          onChange={({ target: { value } }) => {
            if (value === 'no-category') {
              delete params.c
            } else {
              params.c = value
            }
            history.replace(`/campaigns?${qs.stringify(params)}`)
          }}
        >
          <option value='no-category'>All Categories</option>
          {categories.map(({ name, id }) => {
            return (
              <option key={id} value={id}>
                {name}
              </option>
            )
          })}
        </select>
        <select
          className='p-2 col-span-6 md:col-span-4 lg:col-span-3'
          value={params.t || 'no-time'}
          onChange={({ target: { value } }) => {
            if (value === 'no-time') {
              delete params.t
            } else {
              params.t = value
            }
            history.replace(`/campaigns?${qs.stringify(params)}`)
          }}
        >
          <option value='no-time'>Any Time</option>
          <option value='live'>Live</option>
          <option value='future'>Future</option>
          <option value='finished'>Finished</option>
        </select>
        <TextInput
          className='col-span-12 md:col-span-4 lg:col-span-3'
          placeholder='Search'
          value={params.q || ''}
          onTextChange={q => {
            if (q.length === 0) {
              delete params.q
            } else {
              params.q = q
            }
            history.replace(`/campaigns?${qs.stringify(params)}`)
          }}
        />
      </div>
      <CampaignList campaigns={campaigns} />
      {campaigns.length === 0 && (
        <div className='flex justify-center mt-5'>
          <img
            src={icRafflesEmpty}
            alt='Campaigns Empty'
            className='h-64 object-contain'
          />
        </div>
      )}
    </div>
  )
}
