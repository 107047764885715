import { Helmet } from 'react-helmet'
import { Link, Route, Switch } from 'react-router-dom'
import createTitle from '../../utils/createTitle'
import { auth } from '../../utils/firebase'
import Billing from './Billing'
import ChangePassword from './ChangePassword'
import billingIcon from './ic-billing-payment.svg'
import notificationsIcon from './ic-notifications-settings.svg'
import personalInfoIcon from './ic-personal-settings.svg'
import passwordIcon from './ic-update-password.svg'
import NotificationSettings from './NotificationSettings'
import PersonalInformation from './PersonalInformation'

const Item = ({
  title,
  image,
  path,
  backgroundColor
}: {
  title: string
  image: string
  path: string
  backgroundColor: string
}) => {
  return (
    <Link
      to={path}
      className='Link overflow-hidden col-span-12 hover:bg-gray-200 md:col-span-6 lg:col-span-4 xl:col-span-3 bg-white shadow rounded flex p-3 justify-between relative'
      style={{ backgroundColor }}
    >
      <div className='text-sm pr-8 z-10'>{title}</div>
      <img
        src={image}
        alt={title}
        className='h-24 w-24 object-contain z-10'
      />
      <div
        className='bg-white w-96 h-96 absolute z-0'
        style={{ transform: 'rotate(65deg)', top: -290, left: -180 }}
      ></div>
    </Link>
  )
}

export default () => {
  return (
    <Switch>
      <Route
        exact
        path='/portal/settings/info'
        component={PersonalInformation}
      />
      <Route
        exact
        path='/portal/settings/password'
        component={ChangePassword}
      />
      <Route
        exact
        path='/portal/settings/notifications'
        component={NotificationSettings}
      />
      <Route exact path='/portal/settings/payment' component={Billing} />
      <Route>
        <div>
          <Helmet>
            <title>{createTitle('Settings')}</title>
          </Helmet>
          <div className='mb-4 text-2xl'>Settings</div>
          <div className='grid grid-cols-12 gap-4'>
            <Item
              title='Personal Information'
              path='/portal/settings/info'
              image={personalInfoIcon}
              backgroundColor='#e4eaef'
            />
            <Item
              title='Notification Preferences'
              path='/portal/settings/notifications'
              image={notificationsIcon}
              backgroundColor='#fef6ec'
            />
            <Item
              title='Billing & Payment'
              path='/portal/settings/payment'
              image={billingIcon}
              backgroundColor='#f0f6f2'
            />
            <Item
              title='Update Password'
              path='/portal/settings/password'
              image={passwordIcon}
              backgroundColor='#feeaeb'
            />
            <div className='flex items-center col-span-full'>
              <button
                className='button button-yellow px-12 text-lg'
                onClick={() => {
                  auth.signOut()
                }}
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </Route>
    </Switch>
  )
}
