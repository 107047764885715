import clsx from 'clsx'

export default ({
  title,
  value,
  colClasses
}: {
  title: string
  value: string | number
  colClasses: string
}) => {
  return (
    <div
      className={clsx(
        'bg-white border border-gray-200 rounded',
        colClasses
      )}
    >
      <div className='text-center text-xs text-gray-400 font-bold pt-4'>
        {title}
      </div>
      <div className='text-4xl text-center p-4'>{value}</div>
    </div>
  )
}
