import { mdiPencil } from '@mdi/js'
import Icon from '@mdi/react'
import { map } from 'lodash'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { platformDoc } from '../../atoms/platformState'
import usePlatform from '../../hooks/usePlatform'
import createTitle from '../../utils/createTitle'
import { firestore } from '../../utils/firebase'
import TextInput from '../TextInput'

const Item = ({ name, id }: { name: string; id: string }) => {
  const [editing, setEditing] = useState<string>()
  const { categories } = usePlatform()

  return (
    <div className='bg-white rounded shadow p-4 col-span-12 md:col-span-6 lg:col-span-4'>
      {editing ? (
        <div>
          <TextInput
            label='Name'
            value={editing}
            onTextChange={setEditing}
          />
          <div className='mt-4 flex items-center justify-between'>
            <button
              className='button button-outline'
              onClick={() => setEditing(undefined)}
            >
              Cancel
            </button>
            <button
              className='button button-yellow'
              onClick={() => {
                platformDoc.update({
                  categories: {
                    ...categories,
                    [id]: editing
                  }
                })
                setEditing(undefined)
              }}
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <div className='flex items-center justify-between'>
          <div>
            <div className='font-bold'>{name}</div>
          </div>
          <div
            className='rounded-full hover:bg-gray-100 p-2 cursor-pointer'
            onClick={() => setEditing(name)}
          >
            <Icon path={mdiPencil} size={1} color='#777' />
          </div>
        </div>
      )}
    </div>
  )
}

const Input = () => {
  const [name, setName] = useState('')
  const { categories } = usePlatform()
  return (
    <div className='mt-4 bg-white rounded shadow p-4'>
      <TextInput label='Name' value={name} onTextChange={setName} />
      <button
        className='button button-green mt-4'
        onClick={() => {
          platformDoc.update({
            categories: {
              ...categories,
              [firestore.collection('fake').doc().id]: name
            }
          })
          setName('')
        }}
      >
        Add Category
      </button>
    </div>
  )
}

export default () => {
  const { categories } = usePlatform()

  return (
    <div>
      <Helmet>
        <title>{createTitle('Categories')}</title>
      </Helmet>
      <div className='text-2xl'>Categories</div>
      <div className='grid grid-cols-12 gap-3 mt-4'>
        {map(categories, (name, id) => {
          return <Item key={id} id={id} name={name} />
        })}
      </div>
      <Input />
    </div>
  )
}
