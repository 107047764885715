import { mdiClose, mdiMenu } from '@mdi/js'
import Icon from '@mdi/react'
import clsx from 'clsx'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Popover } from 'react-tiny-popover'
import { useRecoilValue } from 'recoil'
import userAtom from '../atoms/userAtom'
import viewerAtom from '../atoms/viewerAtom'
import { auth } from '../utils/firebase'
import CartIcon from './CartIcon/CartIcon'
import titleImage from './logo-green.png'

export default ({ light }: { light?: boolean }) => {
  const viewer = useRecoilValue(viewerAtom)
  const user = useRecoilValue(userAtom)
  const [menuOpen, setMenuOpen] = useState(false)
  const dismissMenu = () => setMenuOpen(false)
  const [popoverOpen, setPopoverOpen] = useState(false)

  const buttonClass = [
    'button',
    'button-text',
    'button-compact',
    light ? 'button-light' : ''
  ].join(' ')

  return (
    <div className={clsx(!light && 'bg-white')}>
      <div className='container'>
        <div className='flex items-center py-3'>
          <Link className='Link' to='/'>
            <img
              src={titleImage}
              alt='Fundraise Club'
              className='h-10 mr-3'
            />
          </Link>
          <div className='flex flex-1 items-center justify-between'>
            <div className='items-center hidden md:flex'>
              {/* <Link to='/about' className='Link'>
                  <button className={buttonClass}>What is Fundraise Club?</button>
                </Link> */}
              <Link className='Link' to='/faqs'>
                <button className={buttonClass}>FAQ's</button>
              </Link>
              <Link to='/campaigns?t=live'>
                <button className='button button-green button-compact ml-2'>
                  Live Boards
                </button>
              </Link>
              <Link to='/campaigns'>
                <button
                  className={`button button-compact ${
                    light ? 'button-outline-light' : 'button-outline'
                  } ml-2`}
                >
                  Fundraisers
                </button>
              </Link>
            </div>
          </div>
          <div className='items-center flex ml-2'>
            {viewer ? (
              <Popover
                isOpen={popoverOpen}
                positions={['bottom', 'left']}
                content={
                  <div className='bg-white border-r-2 p-4'>
                    <Link className='Link' to='/portal/dashboard'>
                      <button
                        className='button'
                        onClick={() => {
                          setPopoverOpen(false)
                        }}
                      >
                        My Portal
                      </button>
                    </Link>
                    <div className='mt-2'>
                      <button
                        className='button'
                        onClick={() => {
                          setPopoverOpen(false)
                          auth.signOut()
                        }}
                      >
                        Sign Out
                      </button>
                    </div>
                  </div>
                }
                onClickOutside={() => setPopoverOpen(false)}
              >
                <button
                  className={buttonClass}
                  onClick={() => setPopoverOpen(true)}
                >
                  {user ? `Hello, ${user.firstName}` : 'Menu'}
                </button>
              </Popover>
            ) : (
              <Link to='sign-in'>
                <button className={buttonClass}>Sign In</button>
              </Link>
            )}

            <div className='ml-2'>
              <Link to='/cart'>
                <CartIcon />
              </Link>
            </div>
            <div
              className={clsx(
                'p-3 rounded-full cursor-pointer md-hidden md:hidden ml-2',
                light ? 'hover:bg-gray-700' : 'hover:bg-gray-200'
              )}
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <Icon
                path={menuOpen ? mdiClose : mdiMenu}
                color={light ? 'white' : 'black'}
                size={1}
              />
            </div>
          </div>
        </div>
        {menuOpen && (
          <div className='flex flex-col md:hidden pb-3'>
            {/* <Link to='/about' className='Link' onClick={dismissMenu}>
              <button className={buttonClass}>What is Fundraise Club?</button>
            </Link> */}
            <Link className='Link' to='/faqs' onClick={dismissMenu}>
              <button className={buttonClass}>FAQ's</button>
            </Link>
            <Link to='/campaigns' onClick={dismissMenu}>
              <button className='button button-text text-yellow-500 p-2 underline'>
                Get Started
              </button>
            </Link>
            <div className='flex items-center'>
              <Link to='/campaigns?t=live' onClick={dismissMenu}>
                <button className='button button-green button-compact'>
                  Live Boards
                </button>
              </Link>
              <Link to='/campaigns' onClick={dismissMenu}>
                <button
                  className={`button button-compact ${
                    light ? 'button-outline-light' : 'button-outline'
                  } ml-2`}
                >
                  Fundraisers
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
