import React from 'react'
import BoardState from '../../types/BoardState'
import Campaign from '../../types/Campaign'
import Ticket from '../../types/Ticket'
import AboutCampaign from './AboutCampaign'
import Board from './Board'
import CampaignPageCart from './CampaignPageCart'
import Entries from './Entries'
import HowItWorks from './HowItWorks'

export default ({
  campaign,
  tickets,
  board
}: {
  campaign: Campaign
  tickets: Ticket[]
  board?: BoardState
}) => {
  return (
    <div className='container py-5'>
      <div className='grid grid-cols-12 gap-4'>
        <CampaignPageCart campaign={campaign} />
        {board && (
          <div className='col-span-12 lg:col-span-7'>
            <Board campaign={campaign} board={board} />
          </div>
        )}
        <div className='col-span-12 lg:col-span-5'>
          <HowItWorks />
        </div>
        <div className='col-span-12 lg:col-span-7'>
          <Entries campaign={campaign} tickets={tickets} />
        </div>
        <div className='col-span-12 lg:col-span-5'>
          <AboutCampaign campaign={campaign} />
        </div>
      </div>
    </div>
  )
}
