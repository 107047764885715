import toggleOff from './ic-toggle-off.svg'
import toggleOn from './ic-toggle-on.svg'
export default ({
  text,
  checked,
  onClick
}: {
  text: string
  checked: boolean
  onClick: () => void
}) => {
  return (
    <div
      className='flex items-center cursor-pointer py-1 hover:bg-gray-100'
      onClick={onClick}
    >
      <img
        className='w-8 h-8'
        src={checked ? toggleOn : toggleOff}
        alt={checked ? `${text} On` : `${text} Off`}
      />
      <div className='ml-2 text-sm'>{text}</div>
    </div>
  )
}
