import Campaign from '../types/Campaign'
import { uniq, chunk, flatten } from 'lodash'
import { collections } from './firebase'
import firebase from 'firebase/app'

export default async (campaigns: Campaign[]) => {
  const charityIds = uniq(campaigns.map(c => c.uid))
  const chunks = chunk(charityIds, 10)

  const users2d = await Promise.all(
    chunks.map(async idsChunk => {
      const snap = await collections.users
        .where(firebase.firestore.FieldPath.documentId(), 'in', idsChunk)
        .get()
      return snap.docs.map(doc => doc.data())
    })
  )

  const users = flatten(users2d)

  return campaigns.map(campaign => {
    return {
      ...campaign,
      charity: users.find(user => user.id === campaign.uid)?.charity
    }
  })
}
