import { groupBy, keys, map } from 'lodash'
import { useEffect, useState } from 'react'
import { useCart } from '../atoms/cartAtom'
import Campaign from '../types/Campaign'
import { CartItem } from '../types/User'
import { collections } from '../utils/firebase'

export default (useLastCheckout?: boolean) => {
  const _cart = useCart()
  const lastCheckout = JSON.parse(
    localStorage.getItem('last-checkout') || '[]'
  ) as CartItem[]
  const cart = useLastCheckout ? lastCheckout : _cart

  const [campaigns, setCampaigns] = useState<Record<string, Campaign>>({})

  useEffect(() => {
    const campaignIds = cart.map(t => t.campaignId)
    Promise.all(
      campaignIds.map(campaignId => {
        return collections.campaigns.doc(campaignId).get()
      })
    ).then(campaigns => {
      const result: Record<string, Campaign> = {}
      campaigns.forEach(c => {
        const campaign = c.data()
        if (campaign) {
          result[campaign.id] = campaign
        }
        setCampaigns(result)
      })
    })
  }, [cart])

  let numNumbers = 0
  const groupedByCampaign = groupBy(cart, 'campaignId')
  map(groupedByCampaign, items => {
    numNumbers = keys(groupBy(items, 'number')).length
  })

  const cartTotal = cart.reduce((prev, cur) => {
    const campaign = campaigns[cur.campaignId]
    if (!campaign) {
      return prev
    }

    return prev + cur.shares.length * campaign.pricePerShare
  }, 0)

  return {
    cart,
    numNumbers,
    groupedByCampaign,
    cartTotal,
    campaigns
  }
}
