import { useState } from 'react'
import { useParams } from 'react-router'
import icCongrats from '../ic-congrats.svg'
import InviteModal from '../InviteModal/InviteModal'

export default () => {
  const { id } = useParams<{ id: string }>()
  const [modalOpen, setModalOpen] = useState(false)
  const dismissModal = () => setModalOpen(false)
  return (
    <div className='my-4 container'>
      <div className='flex justify-center'>
        <img
          src={icCongrats}
          alt='Congratulations'
          className='w-40 h-40'
        />
      </div>
      <div className='mt-4 p-5 bg-white rounded shadow flex flex-col items-center'>
        <div className='text-2xl'>
          Congratulations on creating your raffle
        </div>
        <div className='text-sm mt-2 text-center'>
          Now let's invite some buyers. You can share your raffle on social
          media or invite your personal contacts through email
        </div>
        <button
          className='button button-green mt-2'
          onClick={() => setModalOpen(true)}
        >
          Invite Buyers
        </button>
      </div>
      <InviteModal
        isOpen={modalOpen}
        campaignId={id}
        dismiss={dismissModal}
      />
    </div>
  )
}
