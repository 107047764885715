import { mdiHomeOutline } from '@mdi/js'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import userAtom from '../../atoms/userAtom'
import Campaign from '../../types/Campaign'
import createTitle from '../../utils/createTitle'
import formatMoney from '../../utils/formatMoney'
import getActiveCampaigns from '../../utils/getActiveCampaigns'
import PortalRaffleItem from './PortalRaffleItem'
import PortalTabTitle from './PortalTabTitle'

export default () => {
  const user = useRecoilValue(userAtom)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])

  useEffect(() => {
    if (user) {
      getActiveCampaigns(user.id, 10000).then(setCampaigns)
    }
  }, [user, setCampaigns])

  return (
    <div>
      <Helmet>
        <title>{createTitle('Dashboard')}</title>
      </Helmet>
      <PortalTabTitle icon={mdiHomeOutline} title='Dashboard' />
      <div className='grid gap-4 grid-cols-2'>
        <div className='bg-white col-span-2 md:col-span-1 border border-gray-200 rounded p-5'>
          <div className='text-sm'>Campaigns Participated In</div>
          <div className='mt-3 text-3xl'>
            {user?.campaignIds?.length || 0}
          </div>
        </div>
        <div className='bg-white col-span-2 md:col-span-1 border border-gray-200 rounded p-5'>
          <div className='text-sm'>Won</div>
          <div className='mt-3 text-3xl'>
            {formatMoney(user?.won || 0)}
          </div>
        </div>
        {user?.charity && (
          <>
            <div className='bg-white col-span-2 md:col-span-1 border border-gray-200 rounded p-5'>
              <div className='text-sm'>Earned</div>
              <div className='mt-3 text-3xl'>
                {formatMoney(user?.earned || 0)}
              </div>
            </div>
            <div className='bg-white col-span-2 md:col-span-1 border border-gray-200 rounded p-5'>
              <div className='text-sm'>Active Campaigns</div>
              <div className='mt-3 text-3xl'>{campaigns.length}</div>
            </div>
          </>
        )}
      </div>
      {user?.charity && (
        <div>
          <div className='mt-5'>
            <div className='text-lg'>Active Campaigns</div>
            <div className='mt-2 grid gap-3 grid-cols-2'>
              {campaigns.map(campaign => {
                return (
                  <PortalRaffleItem
                    campaign={campaign}
                    key={campaign.id}
                  />
                )
              })}
            </div>
          </div>
        </div>
      )}
      <Link to='/portal/settings/notifications' className='Link'>
        <button className='button button-outline mt-3'>
          Notification Preferences
        </button>
      </Link>
    </div>
  )
}
