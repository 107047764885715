import { mdiChevronDown, mdiChevronUp } from '@mdi/js'
import Icon from '@mdi/react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from '../../Slider'
import BoardState from '../../types/BoardState'
import Campaign from '../../types/Campaign'
import formatMoney from '../../utils/formatMoney'
import playNowClickBroadcast from '../../utils/playNowClickBroadcast'
import theme from '../../utils/theme'

const imageHeight = 240
const imageButtonHeight = 30

export default ({
  campaign,
  board
}: {
  campaign: Campaign
  board?: BoardState
}) => {
  const {
    spots,
    sharesPerSpot,
    pricePerShare,
    charity,
    uid,
    raised,
    imageUrl
  } = campaign
  const goal = pricePerShare * sharesPerSpot * spots
  const [aboutOpen, setAboutOpen] = useState(false)

  return (
    <div>
      <div className='grid gap-4 grid-cols-12'>
        <div className='col-span-12 lg:col-span-6'>
          {aboutOpen ? (
            <div
              className='relative bg-dark-blue text-white p-3'
              style={{
                height: imageHeight,
                paddingRight: imageButtonHeight
              }}
            >
              <div className='text-lg font-bold'>About:</div>
              <div className='mt-2'>{campaign.charity?.description}</div>
              <div
                style={{
                  width: imageHeight,
                  height: imageButtonHeight,
                  right: (imageHeight / 2 - imageButtonHeight / 2) * -1,
                  top: imageHeight / 2 - imageButtonHeight / 2,
                  transform: 'rotate(270deg)'
                }}
                onClick={() => {
                  setAboutOpen(false)
                }}
                className='absolute flex justify-center items-center text-white font-bold text-sm hover:bg-blue-700 cursor-pointer'
              >
                Close
                <Icon
                  className='ml-1'
                  path={mdiChevronDown}
                  size={0.7}
                  color='white'
                />
              </div>
            </div>
          ) : (
            <div
              className='relative flex'
              style={{
                height: imageHeight,
                paddingRight: imageButtonHeight
              }}
            >
              <img
                src={imageUrl}
                alt={campaign.title}
                className='object-cover flex-1'
              />
              <div
                style={{
                  width: imageHeight,
                  height: imageButtonHeight,
                  right: (imageHeight / 2 - imageButtonHeight / 2) * -1,
                  top: imageHeight / 2 - imageButtonHeight / 2,
                  transform: 'rotate(270deg)'
                }}
                onClick={() => {
                  setAboutOpen(true)
                }}
                className='bg-gray-400 absolute flex justify-center items-center text-white font-bold text-sm hover:bg-gray-500 cursor-pointer'
              >
                About Charity
                <Icon
                  className='ml-1'
                  path={mdiChevronUp}
                  size={0.7}
                  color='white'
                />
              </div>
            </div>
          )}
        </div>
        <div className='col-span-12 lg:col-span-6'>
          <h1 className='font-bold text-3xl'>{campaign.title}</h1>
          {charity && (
            <div className='flex'>
              <Link to={`/charities/${uid}`}>
                <div className='flex items-center py-2 pr-2 hover:bg-gray-200'>
                  <img
                    src={charity.imageUrl}
                    alt={charity.name}
                    className='object-cover rounded-md h-12 w-12'
                  />
                  <div className='ml-2 mr-1 font-bold'>By</div>
                  <div className=' font-bold text-colored'>
                    {charity.name}
                  </div>
                </div>
              </Link>

              <div className='flex-1'></div>
            </div>
          )}
          <div className='mt-4 mb-2 flex justify-between items-baseline'>
            <div className='text-3xl font-bold'>{formatMoney(raised)}</div>
            <div className='text-sm font-bold'>FUNDS RAISED</div>
          </div>
          <Slider max={goal} current={raised} />
          <div className='mt-2 text-xs font-bold'>{`${Math.ceil(
            (raised / goal) * 100
          )}% OF
            ${formatMoney(goal)} GOAL`}</div>
          {board && (
            <div className='flex mt-3'>
              <div className='flex-1 flex flex-col justify-center items-center text-primary'>
                <div className='text-3xl font-bold'>{board.fullyOpen}</div>
                <div className='text-xs'>FULLY OPEN</div>
              </div>
              <div
                className='flex-1 flex flex-col justify-center items-center'
                style={{ color: theme.board.partial }}
              >
                <div className='text-3xl font-bold'>
                  {board.partiallyOpen}
                </div>
                <div className='text-xs'>PARTIALLY OPEN</div>
              </div>
              <div className='flex-1 flex flex-col justify-center items-center'>
                <div className='text-3xl font-bold'>{spots}</div>
                <div className='text-xs'>TOTAL</div>
              </div>
            </div>
          )}
          <div className='text-center font-bold mt-4'>Potential:</div>
          <div className='flex items-center flex-wrap'>
            {campaign.prizes.map((prize, index) => {
              return (
                <div className='w-1/3 mt-2 text-center' key={index}>
                  <div className='font-bold text-2xl text-primary'>
                    {formatMoney(prize.amount)}
                  </div>
                  <div className='text-sm'>{prize.description}</div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className='flex justify-center mt-3'>
        <button
          className='button button-yellow px-8'
          onClick={() => {
            playNowClickBroadcast.setState(Math.random())
          }}
        >
          Get Started
        </button>
      </div>
    </div>
  )
}
