const IS_LIVE = true

const liveConfig = {
  endpoint: 'https://api-paynote.seamlesschex.com/v1',
  publishableKey: 'pk_01HTFFPGAQK8JYJHMSR5VV1WSE',
  sandbox: false
}

const sandboxConfig: typeof liveConfig = {
  endpoint: 'https://sandbox-paynote.seamlesschex.com/v1',
  publishableKey: 'pk_test_01HTG2NWVQY1PS2JMS4VNCSAGA',
  sandbox: true
}

export const achConfig = IS_LIVE ? liveConfig : sandboxConfig
