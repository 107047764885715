import { mdiFacebook, mdiGoogle } from '@mdi/js'
import Icon from '@mdi/react'
import firebase from 'firebase/app'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import viewerAtom from '../../atoms/viewerAtom'
import createTitle from '../../utils/createTitle'
import { auth, collections } from '../../utils/firebase'
import TextInput from '../TextInput'
import hero from '../img-contact.jpg'

function showUnknownErrorAlert() {
  window.alert(
    'Signed in, but did not receive user info from Google. Please try from a different browser.'
  )
}

export default () => {
  const { pathname, hash } = useLocation()
  const isCheckout = pathname.includes('checkout')
  const isSignUp =
    pathname === '/sign-up' || (isCheckout && hash !== '#sign-in')
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loading, setLoading] = useState(false)
  const setViewer = useSetRecoilState(viewerAtom)

  async function handleSocialLogin(result: firebase.auth.UserCredential) {
    console.log(result)

    if (result.user && result.additionalUserInfo?.profile) {
      const profile = result.additionalUserInfo.profile as any
      const firstName = profile.given_name || profile.first_name || null
      const lastName = profile.family_name || profile.last_name || null
      const email = profile.email || null

      await result.user.updateProfile({
        displayName: `${firstName} ${lastName}`
      })
      await collections.users.doc(result.user.uid).set(
        {
          firstName,
          lastName,
          id: 'willBeRemoved',
          email
        },
        {
          merge: true
        }
      )

      leave()
    } else {
      showUnknownErrorAlert()
    }
  }

  function leave() {
    if (!isCheckout) history.push('/')
  }

  return (
    <div className='grid grid-cols-12'>
      <Helmet>
        <title>{createTitle(isSignUp ? 'Sign Up' : 'Sign In')}</title>
      </Helmet>
      <div className='hidden lg:block lg:col-span-5'>
        <img
          className='h-full w-full object-cover'
          src={hero}
          alt='Women laughing'
        />
      </div>
      <div className='col-span-12 lg:col-span-7'>
        <div className='container py-5'>
          <h2 className='text-3xl font-bold text-colored'>
            {isCheckout
              ? 'To complete checkout, please sign in'
              : isSignUp
              ? 'We are excited to have you join us'
              : 'Welcome back!'}
          </h2>
          <form
            onSubmit={e => {
              e.preventDefault()
              if (isSignUp) {
                if (password !== confirmPassword) {
                  return alert('Passwords do not match!')
                }
                setLoading(true)
                auth
                  .createUserWithEmailAndPassword(email, password)
                  .then(async res => {
                    if (res.user) {
                      console.log(
                        'Signed in with email/password, uid is',
                        res.user.uid
                      )
                      setViewer({
                        uid: res.user.uid,
                        email: res.user.email || email
                      })
                      await res.user.updateProfile({
                        displayName: `${firstName} ${lastName}`
                      })
                      await collections.users.doc(res.user.uid).set({
                        firstName,
                        lastName,
                        id: 'willBeRemoved',
                        email
                      })

                      leave()
                    } else {
                      showUnknownErrorAlert()
                    }
                  })
                  .catch(e => {
                    console.error(e)
                    setLoading(false)
                    alert(e.message)
                  })
              } else {
                setLoading(true)
                auth
                  .signInWithEmailAndPassword(email, password)
                  .then(({ user }) => {
                    if (user) {
                      console.log(
                        'Signed in with email/password, uid is',
                        user.uid
                      )
                      setViewer({
                        uid: user.uid,
                        email: user.email || email
                      })
                      leave()
                    } else {
                      showUnknownErrorAlert()
                    }
                  })
                  .catch(e => {
                    console.error(e)
                    setLoading(false)
                    alert(e.message)
                  })
              }
            }}
          >
            <div className='mt-4 p-4 rounded shadow bg-white gap-4 grid grid-cols-2'>
              <button
                onClick={e => {
                  e.preventDefault()
                  auth
                    .signInWithPopup(
                      new firebase.auth.FacebookAuthProvider()
                    )
                    .then(handleSocialLogin)
                    .catch(e => {
                      alert(e.message)
                    })
                }}
                style={{ backgroundColor: '#1a77f2' }}
                className='col-span-1 rounded shadow p-3'
              >
                <div className='flex items-center'>
                  <Icon path={mdiFacebook} color='white' size={1} />
                  <div className='text-white text-center flex-1 font-bold'>
                    Login with Facebook
                  </div>
                </div>
              </button>
              <button
                onClick={e => {
                  e.preventDefault()
                  auth
                    .signInWithPopup(
                      new firebase.auth.GoogleAuthProvider()
                    )
                    .then(handleSocialLogin)
                    .catch(e => {
                      alert(e.message)
                    })
                }}
                className='col-span-1 rounded shadow p-3 bg-white hover:bg-gray-200'
              >
                <div className='flex items-center'>
                  <Icon path={mdiGoogle} color='gray' size={1} />
                  <div className='text-center flex-1 font-bold'>
                    Sign {isSignUp ? 'Up' : 'In'} with Google
                  </div>
                </div>
              </button>
              <div className='col-span-2 h-px my-3 bg-gray-400'></div>
              {isSignUp && (
                <>
                  <TextInput
                    className='col-span-2 md:col-span-1'
                    label='First Name'
                    placeholder='Enter first name'
                    value={firstName}
                    onTextChange={setFirstName}
                    required
                  />
                  <TextInput
                    className='col-span-2 md:col-span-1'
                    label='Last Name'
                    placeholder='Enter last name'
                    value={lastName}
                    onTextChange={setLastName}
                    required
                  />
                </>
              )}
              <TextInput
                className='col-span-2'
                label='Email Address'
                type='email'
                placeholder='Enter email'
                value={email}
                onTextChange={setEmail}
                required
              />
              <TextInput
                className='col-span-2'
                label='Password'
                type='password'
                placeholder='Password'
                value={password}
                onTextChange={setPassword}
                required
              />
              {isSignUp && (
                <TextInput
                  className='col-span-2'
                  label='Confirm Password'
                  type='password'
                  placeholder='Confirm Password'
                  required
                  value={confirmPassword}
                  onTextChange={setConfirmPassword}
                />
              )}
            </div>
            <div className='mt-3 flex justify-between items-center'>
              {isSignUp ? (
                <div />
              ) : (
                <Link
                  to='/forgot-password'
                  className='text-gray-500 font-bold text-sm hover:underline'
                >
                  Forgot password?
                </Link>
              )}
              <button
                className='button button-yellow mt-3 px-4'
                type='submit'
                disabled={loading}
              >
                {isSignUp
                  ? loading
                    ? 'Signing Up...'
                    : 'Sign Up'
                  : loading
                  ? 'Signing In...'
                  : 'Sign In'}
              </button>
            </div>
          </form>

          <div className='flex items-center mt-1'>
            <p>
              {isSignUp
                ? 'Already have an account?  '
                : "Don't have an account yet?  "}
            </p>
            <Link
              className='ml-2 hover:underline text-colored font-bold'
              to={
                isCheckout
                  ? isSignUp
                    ? 'checkout#sign-in'
                    : 'checkout'
                  : isSignUp
                  ? '/sign-in'
                  : '/sign-up'
              }
            >
              {isSignUp ? 'Sign In' : 'Sign Up Now'}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
